import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {Descriptions, Button, Col, Drawer, Row, Space, Table, Dropdown, Tag, Image, App, Tooltip} from "antd";
import {useRecoilState} from "recoil";
import {SocketMerchantReceiveTransactionStatusUpdate} from "../../../../../Storage/SocketMerchantReceiveTransactionStatusUpdate";
import {ApiPost} from "../../../../../Supports/ApiManager";
import dayjs from "dayjs";
import {
    DownOutlined,
    UserAddOutlined,
    SettingOutlined,
    HistoryOutlined,
    KeyOutlined,
    LoadingOutlined,
    PlusOutlined,
    UserOutlined
} from "@ant-design/icons";
import TransactionHistoryMerchant from "./TransactionHistoryMerchant";
import Settings from "../../../../../Settings";
import {Link} from "react-router-dom";
import SiteCreate from "./SiteCreate";
import SiteSettings from "./SiteSettings";
import TeamManagerMerchant from "./TeamManager/TeamManagerMerchant";


const CheckMerchantConnection = ({merchantID,showPlain}) => {

    const [getResult,setResult] = useState({status: false, message: "Kontrol Sağlanıyor" });
    const { message, notification, modal }                      = App.useApp();

    useEffect(()=>{

        ApiPost(`/merchant/${merchantID}/check/branding`)
            .then(response => {
                setResult({
                    status: true,
                    message: response.message
                });
            })
            .catch(error => {
                setResult({
                    status: false,
                    message: error.message
                });
            })
    },[])

    if(showPlain !== undefined){
        return getResult.message
    }else if(getResult.status){
        return <Tag color={"success"}>UYGUN</Tag>
    }else{
        return <Tooltip title={getResult.message}>
            <Tag color={"error"}>HATA</Tag>
        </Tooltip>
    }
};


const MerchantManager = () => {

    const { message, notification, modal }                                  = App.useApp();
    const [getStatusReceive,]                                               = useRecoilState(SocketMerchantReceiveTransactionStatusUpdate);
    const [getLoading,setLoading]                                           = useState(false);
    const [getRecords,setRecords]                                           = useState([]);
    const [getDetailsID, setDetailsID]                                      = useState(null);
    const [getDetails, setDetails]                                          = useState({});
    const [getDetailsDrawer, setDetailsDrawer]                              = useState(false);
    const [getSettingsDrawer, setSettingsDrawer]                            = useState(false);
    const [getHistoryDrawer, setHistoryDrawer]                              = useState(false);
    const [getSiteMerchantCreateDrawer, setSiteMerchantCreateDrawer]        = useState(false);
    const [getDetailsViews, setDetailsViews]                                = useState({ site:[], api:[] });
    const [getSiteMerchantTeamDrawer, setSiteMerchantTeamDrawer]            = useState(false);


    const openDetailsFn = (details) => {

        setDetailsID(details.id);
        setDetailsDrawer(true);

        ApiPost(`/merchant/${details.id}/view`)
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setDetails(result);
                setDetailsDrawer(true);

                setDetailsViews({
                    site: [
                        {
                            span:3,
                            key: Math.random().toString(),
                            label:"Logo",
                            children: <Image src={`${Settings.cdn}/${result.logo}`}  fallback={Settings.fallback} width={100} />
                        },
                        {
                            span:3,
                            key: Math.random().toString(),
                            label:"Başlık",
                            children: result.title
                        },
                        {
                            span:3,
                            key: Math.random().toString(),
                            label:"Açıklama",
                            children: result.description
                        },
                        {
                            span:3,
                            key: Math.random().toString(),
                            label:"Aktif methodlar",
                            children: result.apps.value.map(item => <Tag color={"blue-inverse"} style={{border: "1px dashed purple"}}>{result.apps.options[item]}</Tag>)
                        },
                        {
                            span:3,
                            key: Math.random().toString(),
                            label:"Markalama",
                            children: <CheckMerchantConnection showPlain merchantID={details.id}/>
                        },
                        {
                            span:3,
                            key: Math.random().toString(),
                            label:"Site Oluşturan",
                            children: result.merchant_creator !== null  ? <Tag color={"success"}  >{`${result.merchant_creator.name} ${result.merchant_creator.surname}`}</Tag> : <Tag color={"red"} style={{border: "1px dashed purple"}}>Bulunamadı</Tag>
                        },
                        {
                            span:3,
                            key: Math.random().toString(),
                            label:"Oluşturma Tarihi",
                            children: dayjs(result.creation_date).format("DD/MM/YYYY HH:mm")
                        }
                    ],
                    api: [
                        {
                            span:3,
                            key: Math.random().toString(),
                            label: "Api key",
                            children: result.api_key
                        },
                        {
                            span:3,
                            key: Math.random().toString(),
                            label: "Api token",
                            children: result.api_token
                        },
                        {
                            span:3,
                            key: Math.random().toString(),
                            label: "Yatırım Callback",
                            children: <Link target={"_blank"} to={result.api_deposit_callback_url}>{result.api_deposit_callback_url}</Link>
                        },
                        {
                            span:3,
                            key: Math.random().toString(),
                            label: "Çekim Callback",
                            children: <Link target={"_blank"} to={result.api_withdraw_callback_url}>{result.api_withdraw_callback_url}</Link>
                        },
                    ]
                });

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            });

    };


    const openSettingsFn = (details) => {

        setDetailsID(details.id);
        setSettingsDrawer(false);

        ApiPost(`/merchant/${details.id}/view`)
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setSettingsDrawer(true);
                setDetails(result);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            });



    };


    const openTeamFn = (details) => {

        setDetailsID(details.id);
        setSiteMerchantTeamDrawer(false);

        ApiPost(`/merchant/${details.id}/view`)
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setSiteMerchantTeamDrawer(true);
                setDetails(result);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            });



    };


    const getMerchants = () => {

        setLoading(true);
        ApiPost("/merchants")
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setRecords([]);
                result.map(item => setRecords(getRecords => [...getRecords,item]));
                setLoading(false);


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoading(false);
                console.error("Error : ",error);
            })

    };


    useEffect(getMerchants,[])


    const columns = useCallback([
        {
            title: 'Logo',
            dataIndex: 'logo',
            align: 'center',
            width:100,
            render:(_,{logo}) => <Image width={50} src={`${Settings.cdn}/${logo}`}   fallback={Settings.fallback}/>
        },
        {
            title: 'Başlık',
            dataIndex: 'title',
            align: 'left',
        },
        {
            title: 'Açıklama',
            dataIndex: 'description',
            align: 'left',
        },
        {
            title: 'Site',
            dataIndex: 'brand_domain',
            align: 'left',
        },
        {
            title: 'Markalama',
            dataIndex: 'id',
            align:"center",
            render: (_,{id}) => <CheckMerchantConnection merchantID={id}/>
        },
        {
            title: 'Oluşturan',
            dataIndex: 'merchant_creator',
            align:"center",
            render:(_,{merchant_creator}) => {

                if(merchant_creator !== null){
                    return `${merchant_creator?.name} ${merchant_creator?.surname}`
                }else{
                    return <Tag color={"red"} style={{border: "1px dashed purple"}}>Bulunamadı</Tag>
                }

            }
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            width:100,
            align: 'center',
            render: (_,{live_status}) => {
                if(live_status.value === "active"){
                    return <Tag color={"success"}>Aktif</Tag>
                }else{
                    return <Tag color={"error"}>Pasif</Tag>
                }
            }
        },
        {
            title: 'Markalama',
            width:100,
            align: 'center',
            dataIndex: 'brand_status',
            render: (_,{brand_status}) => {
                if(brand_status.value === "active"){
                    return <Tag color={"success"}>Aktif</Tag>
                }else{
                    return <Tag color={"error"}>Pasif</Tag>
                }
            }
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'creation_date',
            render:(_,{creation_date}) => dayjs(creation_date).format("DD/MM/YYYY HH:mm:ss")
        },
        {
            title: 'İşlemler',
            dataIndex: 'transaction_id',
            width:170,
            align: 'center',
            render:(_,details) => {

                return (
                    <Space>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        icon: <HistoryOutlined />,
                                        label: "İşlem Geçmişi",
                                        key: '0',
                                        onClick:() => {
                                            setDetailsID(details.id);
                                            setHistoryDrawer(true);
                                        }
                                    },
                                    {
                                        icon: <KeyOutlined />,
                                        label: "Künye Bilgileri",
                                        key: '1',
                                        onClick:() => openDetailsFn(details)
                                    },
                                    {
                                        icon: <UserOutlined />,
                                        label: "Ekip Yönetimi",
                                        key: '2',
                                        onClick:() => openTeamFn(details)
                                    },
                                    {
                                        icon: <SettingOutlined />,
                                        label: "Ayarlar",
                                        key: '3',
                                        onClick:() => openSettingsFn(details)
                                    }
                                ],
                            }}
                            trigger={['click']}>
                            <Button loading={details.id === getDetailsID} type="primary" icon={<DownOutlined />} >
                                İşlem Seçiniz
                            </Button>
                        </Dropdown>


                    </Space>
                )

            }
        },
    ],[]);


    return(
        <div style={{}}>


            <Row>
                <Col span={24}>
                    <Table
                        loading={getLoading}
                        style={{ width:"100%"}}
                        size={"small"}
                        scroll={{x:1300,y:400}}
                        columns={columns}
                        dataSource={getRecords}
                        bordered
                        title={
                            () => <>
                                <Space style={{display:"flex", justifyContent:"end"}}>
                                    <Button onClick={()=>setSiteMerchantCreateDrawer(true)} icon={<UserAddOutlined />} type={"default"} shape={"round"}>Müşteri Oluştur</Button>
                                </Space>
                            </>}
                        pagination={true}
                    />
                </Col>
            </Row>




            <Drawer
                title="Site Ekip Yönetimi"
                width={"90%"}
                placement="right"
                onClose={()=>{
                    setSiteMerchantTeamDrawer(false);
                    setDetailsID(null);
                }}
                open={getSiteMerchantTeamDrawer}>
                { getSiteMerchantTeamDrawer&& <TeamManagerMerchant merchantID={getDetailsID}  onChange={() => {
                    getMerchants();
                }}/>}
            </Drawer>


            <Drawer
                title="Site Ayarları"
                width={450}
                placement="right"
                bodyStyle={{paddingTop:0}}
                onClose={()=>setSettingsDrawer(false)}
                open={getSettingsDrawer}>
                { getSettingsDrawer&& <SiteSettings merchantID={getDetailsID}  onChange={() => {
                    getMerchants();
                }}/>}
            </Drawer>

            <Drawer
                title="Site Kayıt"
                width={450}
                placement="right"
                onClose={()=>setSiteMerchantCreateDrawer(false)}
                open={getSiteMerchantCreateDrawer}>
                { getSiteMerchantCreateDrawer && <SiteCreate onChange={() => {
                    setSiteMerchantCreateDrawer(false);
                    getMerchants();
                }}/>}
            </Drawer>


            <Drawer
                title="Geçmiş İşlemler"
                height={"90%"}
                placement="bottom"
                onClose={() => {
                    setDetailsID(null);
                    setHistoryDrawer(false);
                }}
                open={getHistoryDrawer}>
                { getHistoryDrawer && <TransactionHistoryMerchant merchantID={getDetailsID} />}
            </Drawer>

            <Drawer
                title="Site Bilgileri"
                width={650}
                placement="right"
                onClose={() => {
                    setDetailsID(null);
                    setDetailsDrawer(false);
                }}
                open={getDetailsDrawer}>
                { getDetailsDrawer && <Descriptions title="Site Bilgileri"  size={"small"} bordered items={getDetailsViews.site} /> }
                { getDetailsDrawer && <Descriptions title="Api Bilgileri"  size={"small"} bordered items={getDetailsViews.api} style={{marginTop:25}} /> }
            </Drawer>



        </div>
    )

};


export default MerchantManager;