import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Spin, Input, Row, Col, Select, App} from "antd";
import {io} from "socket.io-client";
import success_payment from "../../../Assets/lottiefiles/success_payment.json";
import failed_payment from "../../../Assets/lottiefiles/failed_payment.json";
import wait_payment from "../../../Assets/lottiefiles/wait_payment.json";
import {LoadingOutlined, UserOutlined, KeyOutlined, FilterOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import Settings from "../../../Settings";
import {ApiPostPaymentGateway} from "../../../Supports/ApiManager";
import CreditCardInput from 'react-credit-card-input';
import PaymentMethods from '../../../Assets/methods.png';
import "../../../Assets/public.css"
import {Player} from "@lottiefiles/react-lottie-player";

const CreditCardInformationComponent = ({getTransaction,getAmount}) => {


    const {message}                         = App.useApp();
    const [getBanks,setBanks]               = useState([])
    const [getBank,setBank]                 = useState("")
    const [getCardOwner,setCardOwner]       = useState("")
    const [getCardNo,setCardNo]             = useState("")
    const [getExpire,setExpire]             = useState("")
    const [getCv2,setCv2]                   = useState("")
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)


    const sendRequest = () => {

        if(getBank.length <= 2 ){
            message.error(t("card_owner_info"));
            return;
        }

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/card`,{
            bank_info: getBank,
            card_owner: getCardOwner,
            card_number: getCardNo.replaceAll(" ",""),
            card_expire: getExpire.replaceAll(" ",""),
            card_cvv: getCv2.replaceAll(" ",""),
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }


    useEffect(()=>{


        setLoaderBanks(true);
        ApiPostPaymentGateway(`/banks`,{  })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setLoaderBanks(false);
                setBanks([]);
                Object.values(response.result).map(item => setBanks(current => [...current, {
                    key: Math.random().toString(),
                    label: item,
                    value: item
                }]))
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoaderBanks(false);
            })

    },[])

    return <>
        <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
            <div style={{padding:17, paddingBottom:0}}>
                <Select loading={getLoaderBanks} onChange={e => setBank(e)} placeholder={t("select_bank")}  options={getBanks} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:17}} />
            </div>
            <div style={{padding:17, paddingBottom:0}}>
                <Input prefix={<UserOutlined />} onChange={e => setCardOwner(e.target.value)} placeholder={t("card_owner")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
            </div>
            <div style={{padding:17, paddingBottom:0}}>
                <CreditCardInput
                    containerStyle={{ borderRadius:6, width:"100%"}}
                    fieldStyle={{ borderRadius:6,border:"1px solid #00000036"}}
                    cardCVCInputProps={{
                        onBlur: e => console.log('cvc blur', e),
                        onChange: e => setCv2(e.target.value),
                        onError: err => console.log(`cvc error: ${err}`)
                    }}
                    cardExpiryInputProps={{
                        onBlur: e => console.log('expiry blur', e),
                        onChange: e => setExpire(e.target.value),
                        onError: err => console.log(`expiry error: ${err}`)
                    }}
                    cardNumberInputProps={{
                        onBlur: e => console.log('number blur', e),
                        onChange: e => setCardNo(e.target.value),
                        onError: err => console.log(`number error: ${err}`)
                    }}
                    fieldClassName="input"
                    customTextLabels={{
                        invalidCardNumber: t("invalidCardNumber"),
                        expiryError: {
                            invalidExpiryDate: t("invalidCardNumber"),
                            monthOutOfRange: t("monthOutOfRange"),
                            yearOutOfRange: t('yearOutOfRange'),
                            dateOutOfRange: t('dateOutOfRange')
                        },
                        invalidCvc: t("invalidCvc"),
                        invalidZipCode: t("invalidZipCode"),
                        cardNumberPlaceholder: t("cardNumberPlaceholder"),
                        expiryPlaceholder: t("expiryPlaceholder"),
                        cvcPlaceholder: t("cvcPlaceholder"),
                        zipPlaceholder: t("zipPlaceholder")
                    }}
                />
            </div>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>

        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={sendRequest}>{t("pay")}</Button>
        </div>
    </>
};


const PaymentOk = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={success_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_ok")}</div>
    </>
}

const PaymentNO = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={failed_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_no")}</div>
    </>
}

const PaymentWait = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={wait_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_confirm")}</div>
    </>
}

const CreditCardC2VComponent = ({getTransaction,getAmount}) => {

    const {message}                         = App.useApp();
    const [get3DPin,set3DPin]               = useState("")
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)

    const sendRequest = () => {


        if(get3DPin.length <= 2 ){
            message.error(t("card_3d_info"));
            return;
        }

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/secure`,{
            secure_code: get3DPin,
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }
                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
            <div style={{padding:17}}>
                <Input prefix={<KeyOutlined />} onChange={e => set3DPin(e.target.value)} placeholder={"Sms Şifresi"} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:17}} />
            </div>
        </div>

        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px"}}>
            Tutar <span style={{fontWeight:900}}>{getAmount}</span>
        </div>

        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={sendRequest}>{t("pay")}</Button>
        </div>
    </>
};


const CreditCardMethod = (props) => {

    const {message}                         = App.useApp();
    const { t, i18n }                               = useTranslation();
    const [getSocketConnected,setSocketConnected]   = useState("socket_connect_wait")
    const [getAmount,setAmount]                     = useState("0.00")
    const [getInRoom,setInRoom]                     = useState(false)
    const [getLoadingConfirm,setLoadingConfirm]     = useState(false)
    const [getTransaction,setTransaction]           = useState(props)
    const [getSocketInit,setSocketInit]             = useState(false)

    useEffect(() => {
        window.addEventListener("focus", getUpdate);
        return () => {
            window.removeEventListener("focus", getUpdate);
        };
    }, []);
    
    const getUpdate = () => {

        ApiPostPaymentGateway(`/public/payment/transaction/${getTransaction.transaction_id}/view`)
            .then(response => {


                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setTransaction(response.result);


                setInRoom(response.result.status.value !== "payment_waiting_room");

                if(getSocketInit){
                    getSocketInit.connect();
                }
                
                if(response.result.status.value === "payment_success"){
                    setTimeout(() => {
                        window.location.href = response.result.success_callback;
                    },2000)
                }

                if(response.result.status.value === "payment_failed"){
                    setTimeout(() => {
                        window.location.href = response.result.error_callback;
                    },2000)
                }

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

    }



    useEffect(() => {


        setInRoom(getTransaction.status.value !== "payment_waiting_room");

        const formatter = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: getTransaction.currency.value
        });

        console.log(formatter.format(getTransaction.amount))

        setAmount(formatter.format(getTransaction.amount));


        const SocketConnectServer = io(Settings.socket_server,{
            query: {
                merchant_id: getTransaction.merchant_id,
                transaction_id: getTransaction.transaction_id,
                sender: "client"
            }
        });

        setSocketInit(SocketConnectServer);


        SocketConnectServer.on('connect', ()=>{

            SocketConnectServer.emit("public:payment:join:send",{random:Math.random()})

            console.info("Soket bağlantısı sağlandı");

            setSocketConnected("socket_connect_ok");

            setTimeout(()=>setSocketConnected("socket_connected"),500)

        });

        SocketConnectServer.on('public:payment:check:receiver] ', ()=>{


            console.info("public:payment:check:receiver");

        });

        SocketConnectServer.on("disconnect", (disconnect) => {
            getUpdate();
            setInterval(() => {
                SocketConnectServer.connect();
            }, 1000);
            setSocketConnected("disconnect");
            console.error("Soket bağlantısı koptu");
        });

        SocketConnectServer.on("system:payment:list:receiver", (data) => {
            getUpdate();
            console.info("Soket bağlantısı güncellendi","system:payment:list:receiver");
        });

        SocketConnectServer.on("merchant:payment:create:receiver", (data) => {

            console.info("Soket bağlantısı güncellendi","merchant:payment:create:receiver");

        });


        SocketConnectServer.on("connect_error", () => {
            setInterval(() => {
                SocketConnectServer.connect();
            }, 1000);
        });


        return () => {

            SocketConnectServer.offAny()
            SocketConnectServer.removeAllListeners();
            SocketConnectServer.disconnect();
            setSocketConnected("socket_connect_no");

            console.info("Soket bağlantısı güncellendi","Bağlantı kapatıldı");

        }

    }, []);




    if(getSocketConnected === "socket_connected"){

        if(getInRoom){
            return (<>

                <div style={{fontSize: "22px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", fontWeight: 800, textTransform: "uppercase", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", background: "#000000", color: "white"}}>
                    VISA / MASTERCARD
                </div>

                { ["card_info_request"].includes(getTransaction?.status?.value) && <CreditCardInformationComponent getTransaction={getTransaction} getAmount={getAmount} /> }

                { ["card_info_ok"].includes(getTransaction?.status?.value) && <PaymentWait getTransaction={getTransaction} getAmount={getAmount}/> }

                { ["waiting_3d_pin_request"].includes(getTransaction?.status?.value) && <CreditCardC2VComponent getTransaction={getTransaction} getAmount={getAmount} /> }

                { ["waiting_3d_pin_ok","payment_on_process"].includes(getTransaction?.status?.value) && <PaymentWait getTransaction={getTransaction} getAmount={getAmount} /> }

                { ["payment_success"].includes(getTransaction?.status?.value) && <PaymentOk getTransaction={getTransaction} getAmount={getAmount} /> }

                { ["payment_failed"].includes(getTransaction?.status?.value) && <PaymentNO getTransaction={getTransaction} getAmount={getAmount} /> }


                <div style={{width:"100%", display:"flex", justifyContent:"center", position:"absolute", top:-100}}>
                    <img src={`${Settings.cdn}/${getTransaction.merchant.logo}`} alt={getTransaction.merchant.title} height={90} style={{width:"fit-content"}}/>
                </div>
                <div style={{width:"100%", display:"flex", justifyContent:"center", position:"absolute", bottom:-50}}>
                    <img src={PaymentMethods} alt={getTransaction.merchant.title} height={35} style={{width:"fit-content"}}/>
                </div>

            </>)
        }else{
            return (<>
                <div style={{width:"100%", height: "auto", display:"flex", justifyContent:"center", alignItems:"center", background:"#FFFFFF"}}>

                    <div style={{width:450, height: "auto", padding:20, background:"#FFFFFF", minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>

                        <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color:"black" }} spin />} />
                        <div style={{ textAlign:"center" }}>
                            <div style={{fontSize:17, fontWeight:400}}>{t("in_wait")}</div>
                        </div>


                    </div>

                </div>
            </>)
        }

    }else{

        return (<>
            <div style={{width:"100%", height: "auto", display:"flex", justifyContent:"center", alignItems:"center", background:"#FFFFFF"}}>

                <div style={{width:"100%", height: "auto", padding:20, background:"#FFFFFF", minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>

                    <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color:"black" }} spin />} />
                    <div style={{ textAlign:"center" }}>
                        <div style={{fontSize:17, fontWeight:400}}>Bağlantı Sağlanıyor</div>
                    </div>

                </div>

            </div>
        </>)
    }

};

export default CreditCardMethod;