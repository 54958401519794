import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';


const ErrorPageRouter = () => {

    const navigate = useNavigate();

    return (
        <Result
            status="404"
            title="404"
            subTitle="Üzgünüz, Aradığınız sayfayı bulamadık."
        />
    );
};

export default ErrorPageRouter;