import axios from "axios";
import FormData from 'form-data';
import Settings from "../Settings";

const Server = {
    url: `${Settings.api_endpoint}/admin`,
    public: `${Settings.api_endpoint}`,
};

const ApiPost = (actions,data,callback = ()=>{}) => {

    let formDataListener = new FormData();

    for(let key in data){
        if(Array.isArray(data[key])){
            for(let keyInner in data[key]){
                formDataListener.append(key+"[]", data[key][keyInner])
            }
        }else{
            formDataListener.append(key, data[key])
        }
    }

    let letAutoHeaderAppend = {};

    if(localStorage.getItem("token") !== null){
        letAutoHeaderAppend = {
            headers:  {
                'Authorization' : 'Bearer ' + localStorage.getItem("token"),
            }
        };
    }

    return axios.postForm( Server.url + actions, formDataListener, {
        timeout: 1000 * 120,
        onUploadProgress: (p) => {
            return callback(((p.loaded / p.total) * 100).toFixed(0))
        },
        ...letAutoHeaderAppend
    }).then( response => response.data )

}

const ApiPostPaymentGateway = (actions,data,callback = ()=>{}) => {

    let formDataListener = new FormData();

    for(let key in data){
        if(Array.isArray(data[key])){
            for(let keyInner in data[key]){
                formDataListener.append(key+"[]", data[key][keyInner])
            }
        }else{
            formDataListener.append(key, data[key])
        }
    }

    let letAutoHeaderAppend = {};

    return axios.postForm( Server.public + actions, formDataListener, {
        timeout: 1000 * 120,
        onUploadProgress: (p) => {
            return callback(((p.loaded / p.total) * 100).toFixed(0))
        },
        ...letAutoHeaderAppend
    }).then( response => response.data )

}

const ApiGet = (actions,callback = ()=>{}) => {

    let letAutoHeaderAppend = {};

    if(localStorage.getItem("token") !== null){
        letAutoHeaderAppend = {
            headers:  {
                'Authorization' : 'Bearer ' + localStorage.getItem("token"),
            }
        };
    }

    return axios.get(Server.url + actions, {
        timeout: 1000 * 120,
        onUploadProgress: (p) => {
            return callback(((p.loaded / p.total) * 100).toFixed(0))
        },
        ...letAutoHeaderAppend
    }).then( response => response.data )

}

const ApiPostFileGateway = (actions,data,callback = ()=>{}) => {

    let formDataListener = new FormData();

    for(let key in data){
        if(Array.isArray(data[key])){
            for(let keyInner in data[key]){
                formDataListener.append(key+"[]", data[key][keyInner])
            }
        }else{
            formDataListener.append(key, data[key])
        }
    }


    let letAutoHeaderAppend = {};

    if(localStorage.getItem("token") !== null){
        letAutoHeaderAppend = {
            headers:  {
                'Authorization' : 'Bearer ' + localStorage.getItem("token"),
            }
        };
    }

    return axios.postForm( Server.public + actions, formDataListener, {
        timeout: 1000 * 120,
        onUploadProgress: (p) => {
            return callback(((p.loaded / p.total) * 100).toFixed(0))
        },
        ...letAutoHeaderAppend
    }).then( response => response.data )

}

const ApiPostFile = (actions,data,callback = ()=>{}) => {

    let formDataListener = new FormData();

    for(let key in data){
        if(Array.isArray(data[key])){
            for(let keyInner in data[key]){
                formDataListener.append(key+"[]", data[key][keyInner])
            }
        }else{
            formDataListener.append(key, data[key])
        }
    }


    let letAutoHeaderAppend = {};

    if(localStorage.getItem("token") !== null){
        letAutoHeaderAppend = {
            headers:  {
                'Authorization' : 'Bearer ' + localStorage.getItem("token"),
            }
        };
    }

    return axios.postForm( Server.url + actions, formDataListener, {
        responseType: 'blob',
        timeout: 1000 * 120,
        onUploadProgress: (p) => {
            return callback(((p.loaded / p.total) * 100).toFixed(0))
        },
        ...letAutoHeaderAppend
    }).then( response => response.data )

}
const ApiGetFile = (actions,callback = ()=>{}) => {

    let letAutoHeaderAppend = {};

    if(localStorage.getItem("token") !== null){
        letAutoHeaderAppend = {
            headers:  {
                'Authorization' : 'Bearer ' + localStorage.getItem("token"),
            }
        };
    }

    return axios.get(Server.url + actions, {
        responseType: 'blob',
        timeout: 1000 * 120,
        onUploadProgress: (p) => {
            return callback(((p.loaded / p.total) * 100).toFixed(0))
        },
        ...letAutoHeaderAppend
    }).then( response => response.data )

}

export {

    ApiPost,
    ApiGet,

    ApiPostPaymentGateway,

    ApiPostFile,
    ApiGetFile,

    ApiPostFileGateway,
    Server
};
