import {atom, selector} from "recoil";

const getDefaultColorTheme = () => {
    const getThemeLocalStorage = window.localStorage.getItem("color-theme");
    if(getThemeLocalStorage == null){
        return "light";
    }else if(["dark","light"].includes(getThemeLocalStorage)){
        return getThemeLocalStorage;
    }else{
        return "light";
    }
};

const ColorStorageState = atom({
    key: 'ColorStorageStateKey',
    default: getDefaultColorTheme(),
});

const ColorStorageValue = selector({
    key: 'ColorStorageValueKey',
    get: ({get}) => {
        return get(ColorStorageState);
    },
});


export {
    ColorStorageState,
    ColorStorageValue
}