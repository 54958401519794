import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Form, DatePicker, Button, Select, Space, Badge, Tag, Table, Statistic} from "antd";
import {useRecoilState} from "recoil";
import {SocketMerchantReceiveTransactionUpdate} from "../../../../../Storage/SocketMerchantReceiveTransactionUpdate";
import {ApiPost} from "../../../../../Supports/ApiManager";
import {ArrowRightOutlined, FilterOutlined} from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/tr_TR';
import 'dayjs/locale/tr';
import dayjs from "dayjs";
import {PaymentColors, PaymentStatusColor, validateEmail} from "../../../../../Helper";
import CountUp from "react-countup";

const TransactionHistoryMerchant = ({merchantID}) => {

    const [getLoading,setLoading]                               = useState(true);
    const [getSocketReceive,setSocketReceive]                   = useRecoilState(SocketMerchantReceiveTransactionUpdate);
    const [searchForm]                                          = Form.useForm();

    const [clientReady, setClientReady]                         = useState(true);

    const [getMethodsLoading, setMethodsLoading]                = useState(true);
    const [getMethods, setMethods]                              = useState([]);

    const [getStatusLoading, setStatusLoading]                  = useState(true);
    const [getStatus, setStatus]                                = useState([]);


    const [getRecordsLoading, setRecordsLoading]    = useState(false);
    const [getRecords, setRecords]                  = useState([]);

    const [getPriceTotal, setPriceTotal]                        = useState(0);



    const [getCurrencyLoading, setCurrencyLoading]    = useState(false);
    const [getCurrency, setCurrency]                  = useState([]);



    useEffect(() => {

        setClientReady(true);
        setMethodsLoading(true);
        setStatusLoading(true);


        ApiPost("/merchant/payment/method",{  })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setMethods([
                    {
                        key: Math.random().toString(),
                        label: "Hepsi",
                        value: "all"
                    }
                ]);

                setMethodsLoading(false);


                response.result.map(item => setMethods(current => [...current,item]));


            }).catch(error =>{  })


        ApiPost("/merchant/payment/status",{  })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setStatusLoading(false);

                setStatus([
                    {
                        key: Math.random().toString(),
                        label: "Hepsi",
                        value: "all"
                    }
                ]);

                Object.keys(response.result).map(item => setStatus(current => [...current,{
                    key: Math.random().toString(),
                    label: response.result[item],
                    value: item
                }]))

            }).catch(error =>{  })




        setCurrencyLoading(true);

        setCurrency([
            {
                key: Math.random().toString(),
                label: "Hepsi",
                value: "all"
            }
        ]);

        Object.keys(PaymentColors).map(item => setCurrency(current => [...current,{
            key: Math.random().toString(),
            label: item,
            value: item
        }]))

        setCurrencyLoading(false);



        setClientReady(true);

    }, []);


    const historyApiRequestQuery = (values) => {


        setRecordsLoading(true);
        ApiPost(`/merchant/${merchantID}/history/search`,{
            start: dayjs(values.dates[0]).format("YYYY-MM-DD HH:mm:ss"),
            finish: dayjs(values.dates[1]).format("YYYY-MM-DD HH:mm:ss"),
            status: values.status,
            method: values.method,
            currency: values.currency,
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setRecords([]);

                setPriceTotal(0);
                response.result.map(item => setRecords(getRecords => [...getRecords,item]));
                response.result.map(item => setPriceTotal(getRecords => getRecords+item.amount));
                setRecordsLoading(false);

            }).catch(error =>{  })


    };

    const columns = useCallback([
        {
            title: 'Kullanıcı Adı',
            dataIndex: 'client_username',
            align: 'left',
        },
        {
            title: 'Kullanıcı E-Posta',
            dataIndex: 'client_email',
            align: 'left',
            render: (_,{client_email}) => {
                if(validateEmail(client_email)){
                    return <Tag color={"success"}>{client_email}</Tag>
                }else{
                    return <Tag color={"error"}>Bulunamadı</Tag>
                }
            }
        },

        {
            title: 'Sistem Mesajı',
            dataIndex: 'message',
            align: 'left',
        },
        {
            title: 'Method',
            dataIndex: 'method',
            align: 'left',
            render: (_,{method}) => method.label
        },
        {
            title: 'Tutar',
            dataIndex: 'amount',
            render: (_,{amount,currency}) => {
                const formatter = new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: currency.value
                });
                return <Space>
                    <Tag color={PaymentColors[currency.value] ?? "#000000"} style={{border: "1px dashed purple"}}>{currency.value}</Tag>
                    <Tag color={PaymentColors[currency.value] ?? "#000000"} style={{border: "1px dashed purple"}}>{formatter.format(amount)}</Tag>
                </Space>;
            }
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            render: (_,{status}) => {

                return <Space>
                    <Tag color={PaymentStatusColor[status.value] ?? "black"} style={{border: "1px dashed purple"}}>{status.label}</Tag>
                </Space>;
            }
        },
        {
            title: 'Personel',
            dataIndex: 'agent',
            render: (_,{agent}) => {

                if(agent === null){
                    return <Space>
                        <Tag color={"red"} style={{border: "1px dashed purple"}}>Bulunamadı</Tag>
                    </Space>;
                }else{
                    return <Space>
                        {agent.name} {agent.surname}
                    </Space>;
                }
            }
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'creation_date',
            render:(_,{creation_date}) => dayjs(creation_date).format("DD/MM/YYYY HH:mm:ss")
        }
    ],[]);


    return(<>

        <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>

            <div style={{width:"100%", height:200}}>
                <Form form={searchForm} name="horizontal_login" layout="vertical" onFinish={historyApiRequestQuery}>
                    <Card
                        style={{width:"100%"}}
                        extra={<>
                            <Space>
                                <Form.Item shouldUpdate style={{margin:0}}>
                                    {() => (
                                        <Button
                                            icon={<FilterOutlined />}
                                            type="primary"
                                            htmlType="submit"
                                            disabled={ !clientReady ||  !searchForm.isFieldsTouched(true) ||  !!searchForm.getFieldsError().filter(({ errors }) => errors.length).length }>
                                            Bul
                                        </Button>
                                    )}
                                </Form.Item>
                            </Space>
                        </>}
                        title={"Filtreleme Seçenekleri"}>


                        <Row gutter={25}>
                            <Col span={12}>
                                <Form.Item
                                    label={"Tarih Aralığı"}
                                    name="dates"
                                    rules={[{required: true, message: 'Tarih aralığı seçimi zorunludur!' } ]}>
                                    <DatePicker.RangePicker style={{width:"100%"}}  showTime={{ format: 'HH:mm' }}       format="YYYY-MM-DD HH:mm" locale={locale} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label={"Method"}
                                    name="method"
                                    rules={[{required: true, message: 'Method seçimi zorunludur!' } ]}>
                                    <Select
                                        style={{width:"100%"}}
                                        showSearch
                                        loading={getMethodsLoading}
                                        placeholder="Method Seçiniz"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={getMethods}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label={"İşlem Durumu"}
                                    name="status"
                                    rules={[{required: true, message: 'Durum seçimi zorunludur!' } ]}>
                                    <Select
                                        style={{width:"100%"}}
                                        showSearch
                                        loading={getStatusLoading}
                                        placeholder="Durum Seçiniz"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={getStatus}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label={"Para Tipi"}
                                    name="currency"
                                    rules={[{required: true, message: 'Para tipi seçimi zorunludur!' } ]}>
                                    <Select
                                        style={{width:"100%"}}
                                        showSearch
                                        loading={getCurrencyLoading}
                                        placeholder="Durum Seçiniz"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={getCurrency}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </div>

            <div style={{flex:1}}>
                { getRecords.length > 0 && <Statistic title={"Toplam Tutar"} value={getPriceTotal} formatter={value => <CountUp end={value} separator="," />} style={{paddingLeft:"10px", paddingTop: 15, paddingBottom:15}} /> }
                <Table
                    loading={getRecordsLoading}
                    style={{ width:"100%"}}
                    scroll={{x:1200,y:400}}
                    size={"small"}
                    columns={columns}
                    dataSource={getRecords}
                    bordered
                    title={
                        () => <>
                            <Space>
                                <Badge  status={"processing"} text="İşlem Arama Sonuçları" />
                                { Object.keys(PaymentColors).map(item => <Tag key={(Math.random().toString())} color={PaymentColors[item] ?? "#000000"}>{item}</Tag>) }
                            </Space>
                        </>}
                    pagination={false}
                />

            </div>

        </div>
    </>)

};


export default TransactionHistoryMerchant;