import React, {useEffect, useRef, useState} from "react";
import {Button, Drawer, Tabs} from "antd";
import WaitingRoom from "./Payment/WaitingRoom";
import OnProcessRoom from "./Payment/OnProcessRoom";
import {useRecoilState} from "recoil";
import {SocketMerchantReceiveTransactionUpdate} from "../../Storage/SocketMerchantReceiveTransactionUpdate";
import DailyFinance from "./Finance/DailyFinance";
import DailyAgentsFinance from "./Finance/DailyAgentsFinance";
import SystemReportFinance from "./Finance/SystemReportFinance";

const startAt = new Date();
startAt.setHours(0);
startAt.setMinutes(0);

const finishAt = new Date();
finishAt.setHours(23);
finishAt.setMinutes(59);



let items = [
    {
        key: '1',
        label: 'Günlük Canlı Akış',
        children: <DailyFinance startAt={startAt} finishAt={finishAt} />,
    },
    // {
    //     key: '2',
    //     label: 'Gün Sonu Raporları',
    //     children: <DailyAgentsFinance/>,
    // },
    {
        key: '3',
        label: 'Sistem Raporu',
        children: <SystemReportFinance startAt={startAt} finishAt={finishAt} />,
    },
];

const FinanceScreen = () => {

    const [getStatic,setStatic]                 = useState(null);
    const [getHistoryDrawer,setHistoryDrawer]   = useState(false);
    const [getSocketReceive,setSocketReceive]   = useRecoilState(SocketMerchantReceiveTransactionUpdate);


    useEffect(() => {
        const role = localStorage.getItem('role') ?? null;
        if(["admin","finance","merchant","root","operator"].includes(role) === false) {
            items = [];
        } else {
            if(role === "operator") {
                items.splice(0, 1)
            }
        }
    }, [])
    
    return(
        <div style={{padding:15, paddingTop:0, display:"flex", flexDirection:"column"}}>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    )

};


export default FinanceScreen;