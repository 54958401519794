import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {ProgressBar} from "react-windows-ui";
import { useNavigate } from "react-router-dom";
import {ApiPost} from "../Supports/ApiManager";
import {AuthControlStorage} from "../Storage/AuthControlStorage";

const Initial = ({children,app,login}) => {

    const navigate                              = useNavigate();
    const [getAuth,setAuth]                     = useRecoilState(AuthControlStorage);
    const [getLoading,setLoading]               = useState(true);

    useEffect(()=>{

        setLoading(true);
        ApiPost("/merchant/view")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setAuth(true);

                setLoading(false);

                if(login !== undefined){
                    navigate("/app");
                }

            })
            .catch(error => {
                setLoading(false);
                if(!["/auth/login"].includes(window.location.pathname)){
                    navigate("/auth/login");
                }
            })

    },[])

    return(<>

        {
            getLoading &&
                <div style={{width: "100%", height: "100vh", display: "flex", placeContent: "center", alignItems: "center"}}>
                    <div>
                        <ProgressBar  setProgress={"indeterminate"} />
                        <div style={{textAlign:"center", marginTop:15}}>
                            Kontroller Sağlanıyor
                        </div>
                    </div>
                </div>
        }

        { !getLoading &&  children }

    </>);

};


export default Initial