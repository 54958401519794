import React, {useRef, useState} from "react";
import { Button, DatePicker, Input, Space } from "antd";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const handleSearch = (selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
};

export const GetColumnSearchProps = (dataIndex, recordFilterHook = (recordIndexPrefix) => recordIndexPrefix  ) => {


    const searchInput                               = useRef();
    const [searchText, setSearchText]               = useState("");
    const [searchedColumn, setSearchedColumn]       = useState("");

    return ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close,}) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Arama yap...`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys,
                            confirm,
                            dataIndex,
                            setSearchText,
                            setSearchedColumn
                        )
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(
                                selectedKeys,
                                confirm,
                                dataIndex,
                                setSearchText,
                                setSearchedColumn
                            )
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Bul
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters();
                            setSearchText("");
                            confirm({ closeDropdown: false });
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{ width: 90 }}>
                        Temizle
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}>
                        {" "}
                        Kapat{" "}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            recordFilterHook(record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            visible && setTimeout(() => searchInput.current?.select(), 100);
        },
        render: (_,text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={recordFilterHook(text[dataIndex]) ? recordFilterHook(text[dataIndex]).toString() : ""}
                />
            ) : recordFilterHook(text[dataIndex]),
    })
};

export const GetColumnSearchDateProps = (dataIndex,dateFormat = "YYYY-MM-DD") => {

    const searchInput                               = useRef(null);
    const [searchText, setSearchText]               = useState("");
    const [searchedColumn, setSearchedColumn]       = useState("");

    return ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close,}) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <DatePicker
                    format={"DD/MM/YYYY HH:mm"}
                    showTime={true}
                    allowClear={false}
                    onChange={(e) => {
                        setSelectedKeys(
                            dayjs(e).format(dateFormat) ? [dayjs(e).format(dateFormat)] : []
                        );
                        handleSearch(
                            selectedKeys,
                            confirm,
                            dataIndex,
                            setSearchText,
                            setSearchedColumn
                        );
                    }}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            clearFilters();
                            setSearchText("");
                            confirm({ closeDropdown: false });
                            setSearchedColumn(dataIndex);

                        }}>
                        Temizle
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}>
                        Kapat
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            visible && setTimeout(() => searchInput.current?.select(), 100);
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (text),
    })

} ;
