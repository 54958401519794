import React, {useEffect, useState} from "react";
import {App, Button, Form, Input, InputNumber, Modal, Progress, Spin, Upload} from "antd";
import {io} from "socket.io-client";
import {LoadingOutlined, UserOutlined, InboxOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import Settings from "../../../Settings";
import {ApiPostFile, ApiPostFileGateway, ApiPostPaymentGateway} from "../../../Supports/ApiManager";
import {Player} from "@lottiefiles/react-lottie-player";
import success_payment from "../../../Assets/lottiefiles/success_payment.json";
import failed_payment from "../../../Assets/lottiefiles/failed_payment.json";
import wait_payment from "../../../Assets/lottiefiles/wait_payment.json";


const PaymentOk = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={success_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_ok")}</div>
    </>
}

const PaymentNO = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={failed_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_no")}</div>
    </>
}

const PaymentWait = () => {

    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={wait_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_confirm")}</div>
    </>
}


const Step_1 = ({getTransaction,getAmount}) => {

    const {message}                         = App.useApp();
    const [getFormInit]                     = Form.useForm();
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [getPercent,setPercent]             = useState(0)

    const [fileList, setFileList] = useState([]);

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    const sendRequest = (values) => {


        if(fileList.length === 0){
            message.error("Dekont Seçiniz");
            return;
        }


        setLoader(true);
        ApiPostFileGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/manuel_custom_info`,{
            manuel_custom_info:values.manuel_custom_info,
            file: fileList[0]
        },(percent)=> {
            setPercent(percent);
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setLoader(false);


            })
            .catch(error => {

                setPercent(0);
                setFileList([]);
                getFormInit.resetFields();
                Modal.error({ title: "HATA", content: error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message });
                setLoader(false);
            })


    }

    return <>

        {
            getLoader &&
                <div style={{
                    width: "100%",
                    paddingTop: 50,
                    paddingBottom: 50,
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center",
                    alignItems: "center",
                    alignContent: "center"
                }}>
                    <Progress type="circle" percent={getPercent}/>
                </div>

        }

        {
            !getLoader &&
            <>


                <Form
                    form={getFormInit}
                    name="ManuelTransfer"
                    layout={"vertical"}
                    onFinish={sendRequest}
                    onFinishFailed={() => {
                    }}
                    autoComplete="off">
                    <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>

                        <div style={{padding:"0px 17px"}}>
                            <Form.Item
                                label={t("manuel_custom_info")}
                                hasFeedback
                                name="manuel_custom_info"
                                rules={[{required: true, message: 'Bu xananın doldurulması məcburidir!'} ]}>
                                <InputNumber  prefix={"+994"} placeholder="Hesab nömrəsini yazın" maxLength={9} style={{width:"100%"}} />
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                name="file"
                                rules={[{required: true, message: 'Bu xananın doldurulması məcburidir!'} ]}>
                                <Upload.Dragger style={{width: "100%", marginTop:15}} {...props}>
                                    <p className="ant-upload-drag-icon"> <InboxOutlined/> </p>
                                    <p className="ant-upload-text">Qəbzin Yükləmə Sahəsi</p>
                                    <p className="ant-upload-hint">Bu sahəyə sürükləyin və ya seçim üçün klikləyin</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </div>

                    </div>
                </Form>
                <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px"}}>
                    {t("amount")} <span style={{fontWeight:900}}>{getAmount}</span>
                </div>
                <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
                    <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => getFormInit.submit()}>{t("pay")}</Button>
                </div>
            </>
        }

    </>
};

const Method = (props) => {

    const { message, notification, modal }                      = App.useApp();

    const { t, i18n }                               = useTranslation();
    const [getSocketConnected,setSocketConnected]   = useState("socket_connect_wait")
    const [getAmount,setAmount]                     = useState("0.00")
    const [getInRoom,setInRoom]                     = useState(false)
    const [getLoadingConfirm,setLoadingConfirm]     = useState(false)
    const [getTransaction,setTransaction]           = useState(props)
    const [getSocketInit,setSocketInit]             = useState(false)


    useEffect(() => {
        window.addEventListener("focus", getUpdate);
        return () => {
            window.removeEventListener("focus", getUpdate);
        };
    }, []);
    
    const getUpdate = () => {

        ApiPostPaymentGateway(`/public/payment/transaction/${getTransaction.transaction_id}/view`)
            .then(response => {

                setTransaction(response.result);

                setInRoom(response.result.status.value !== "payment_waiting_room");

                if(getSocketInit){
                    getSocketInit.connect();
                }

                if(response.result.status.value === "payment_success"){
                    setInterval(() => {
                        window.location.href = response.result.success_callback;
                    },2000)
                }

                if(response.result.status.value === "payment_failed"){
                    setInterval(() => {
                        window.location.href = response.result.error_callback;
                    },2000)
                }

            })
            .catch(error => {
                message.error(error.message)
            })

    }

    const confirmUpdate = () => {

        setLoadingConfirm(true);

        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/confirm`)
            .then(response => {

                setLoadingConfirm(false);

            })
            .catch(error => {
                message.error(error.message)
                setLoadingConfirm(false);
            })

    }

    useEffect(() => {

        setInRoom(getTransaction.status.value !== "payment_waiting_room");

        const formatter = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: getTransaction.currency.value
        });

        setAmount(formatter.format(getTransaction.amount));

        const SocketConnectServer = io(Settings.socket_server,{
            query: {
                merchant_id: getTransaction.merchant_id,
                transaction_id: getTransaction.transaction_id,
                sender: "client"
            }
        });

        setSocketInit(SocketConnectServer);


        SocketConnectServer.on('connect', ()=>{

            SocketConnectServer.emit("public:payment:join:send",{random:Math.random()})

            console.info("Soket bağlantısı sağlandı");

            setSocketConnected("socket_connect_ok");

            setTimeout(()=>setSocketConnected("socket_connected"),500)

        });

        SocketConnectServer.on("disconnect", (disconnect) => {
            getUpdate();
            setInterval(() => {
                SocketConnectServer.connect();
            }, 1000);
            setSocketConnected("disconnect");
            console.error("Soket bağlantısı koptu");
        });

        SocketConnectServer.on("system:payment:list:receiver", (data) => {
            getUpdate();
            console.info("Soket bağlantısı güncellendi","system:payment:list:receiver");
        });


        SocketConnectServer.on("connect_error", () => {
            setInterval(() => {
                SocketConnectServer.connect();
            }, 1000);
        });

        return () => {

            SocketConnectServer.offAny()
            SocketConnectServer.removeAllListeners();
            SocketConnectServer.disconnect();
            setSocketConnected("socket_connect_no");

            console.info("Soket bağlantısı güncellendi","Bağlantı kapatıldı");

        }

    }, []);

    const copyContent = async (content) => {

        try {
            await navigator.clipboard.writeText(content);
            message.destroy();
            message.success(`(${content}) Kopyalandı`);
        } catch (err) {
            message.error("Kopyalama Hatası");
        }
    }   


    if(getSocketConnected === "socket_connected"){


        if(getInRoom){
            const copyIcon = 'lorem'
            return (<>

                <div style={{fontSize: "22px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", fontWeight: 800, textTransform: "uppercase", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", background: "#000000", color: "white"}}>
                    {getTransaction?.gateway?.method}
                </div>

                { ["payment_success"].includes(getTransaction?.status?.value) && <PaymentOk getTransaction={getTransaction} getAmount={getAmount} /> }

                { ["payment_failed"].includes(getTransaction?.status?.value) && <PaymentNO getTransaction={getTransaction} getAmount={getAmount} /> }

                { ["payment_confirmed","payment_on_process","info_ok"].includes(getTransaction?.status?.value) && <PaymentWait getTransaction={getTransaction} getAmount={getAmount} /> }

                { ["take_sender_account"].includes(getTransaction?.status?.value) && <>


                <div style={{fontSize: "23px", fontWeight:"700" ,letterSpacing:"10px", display:"flex", alignItems:"center", textAlign: "center", borderRadius: "4px", padding: "10px", margin:"0px 15px", borderBottom:"4px solid #17b9ac" }}>
                        <div style={{zIndex:"1", width:"100%"}} dangerouslySetInnerHTML={{__html:getTransaction?.gateway?.content}} onClick={() => copyContent(getTransaction?.gateway?.content)} />
                        <svg style={{zIndex:"0", position:"absolute", right:"30px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
</svg>
                    </div>

                    <Step_1 getTransaction={getTransaction} getAmount={getAmount} />

                    {
                        /*
                            <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px"}}>
                                    {t("sendAmount")} <span style={{fontWeight:900}}>{getAmount}</span>
                                </div>
                                <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:25, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
                                    <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoadingConfirm} onClick={confirmUpdate}>{t("confirmPay")}</Button>
                                </div>
                        */
                    }

                </> }



                <div style={{width:"100%", display:"flex", justifyContent:"center", position:"absolute", marginTop:-100}}>
                    <img src={`${Settings.cdn}/${getTransaction.merchant.logo}`} alt={getTransaction.merchant.title} height={90} style={{width:"fit-content"}}/>
                </div>


            </>)
        }else{
            return (<>
                <div style={{width:"100%", height: "auto", display:"flex", justifyContent:"center", alignItems:"center"}}>

                    <div style={{width:450, height: "auto", padding:20, background:"#FFFFFF", minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>

                        <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color:"black" }} spin />} />
                        <div style={{ textAlign:"center" }}>
                            <div style={{fontSize:17, fontWeight:400}}>{t("in_wait")}</div>
                        </div>

                    </div>

                </div>
            </>)
        }

    }else{

        return (<>
            <div style={{width:"100%", height: "auto", display:"flex", justifyContent:"center", alignItems:"center", background:"#FFFFFF"}}>

                <div style={{width:"100%", height: "auto", padding:20, background:"#FFFFFF", minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>

                    <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color:"black" }} spin />} />
                    <div style={{ textAlign:"center" }}>
                        <div style={{fontSize:17, fontWeight:400}}>Bağlantı Sağlanıyor</div>
                    </div>

                </div>

            </div>
        </>)
    }

};


export default Method;