import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Form, DatePicker, Button, Select, Space, Badge, Tag, Table, TimePicker, Input, App,} from "antd";
import 'dayjs/locale/tr';
import { ReloadOutlined, KeyOutlined,FilterOutlined, UserOutlined, MailOutlined} from '@ant-design/icons';
import {PasswordInput} from "antd-password-input-strength";
import {ApiPost} from "../../../../../../Supports/ApiManager";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {useRecoilState} from "recoil";
import {TimeZonesStorage} from "../../../../../../Storage/TimeZonesStorage";
 dayjs.extend(customParseFormat);



const TeamSettingsMerchant = (props) => {

    const { message, notification, modal }                      = App.useApp();

    const [PersonalForm]                                        = Form.useForm();
    const [PersonalUpdateForm]                                  = Form.useForm();
    const [level, setLevel]                                     = useState(0);
    const [getSubMerchants, setSubMerchants]                    = useState([]);
    const [getLoading, setLoading]                              = useState(false);
    const [getTimeZones,setTimeZones]                           = useRecoilState(TimeZonesStorage);


    useEffect(()=>{

        setLoading(true);

        ApiPost(`/merchant/${props.merchantID}/team/${props?.agentID}/view`)
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setLoading(false);
                PersonalForm.setFieldsValue({
                   name: result.name,
                   surname: result.surname,
                   email: result.email,
                    timezone: result.timezone,
                   status: result.status.value,
                   role: result.role.value,
                    merchant_access: result.merchant_access,
                   "2fa_status": result["2fa_status"]["value"],
                   multiple_tab: result.multiple_tab.value,
                   shift: [dayjs(result.shift_start,"HH:mm:ss"),dayjs(result.shift_finish,"HH:mm:ss")]
                });

            })
            .catch(error => {
                setLoading(false);
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })


        ApiPost(`/merchant/${props.merchantID}/sub/merchants`)
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setSubMerchants([]);
                setLoading(false);
                result.map(item => setSubMerchants(currentItem => [...currentItem,{
                    key: Math.random(),
                    value: item.id,
                    label: item.title
                }]));

            })
            .catch(error => {
                setLoading(false);
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })


        return () => {
            setSubMerchants([]);
        };
    },[])

    const onFinish = (values) => {

        setLoading(true);
        ApiPost(`/merchant/${props.merchantID}/team/${props.agentID}/update`,{
            name:               values.name,
            surname:            values.surname,
            email:              values.email,
            status:             values.status,
            role:               values.role,
            merchant_access:    values.merchant_access === undefined ? [] : values.merchant_access,
            timezone:           values.timezone,
            multiple_tab:       values.multiple_tab,
            "2fa_status":       values["2fa_status"],
            shift_start:        dayjs(values.shift[0]).format("HH:mm:ss"),
            shift_finish:       dayjs(values.shift[1]).format("HH:mm:ss"),
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setLoading(false);

                message.success(response.message);

                props?.onChange(true);


            }).catch(error =>{
                setLoading(false);
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                props?.onChange(true);
            })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishPassword = (values) => {

        setLoading(true);

        ApiPost(`/merchant/${props.merchantID}/team/${props.agentID}/update/password`,{
            password:       values.password,
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setLoading(false);

                message.success(response.message);

                props?.onChange(true);


            }).catch(error =>{
                setLoading(false);
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                props?.onChange(true);
            })

    };
    const onFinishFailedPassword = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return(<>

        <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>

            <Card title={"Profil Ayarları"} extra={  <Button loading={getLoading} icon={<ReloadOutlined />} type="primary" onClick={() => PersonalForm.submit()} block> Güncelle </Button>}>
                <Form
                    disabled={getLoading}
                    form={PersonalForm}
                    name="PersonalForm"
                    layout={"vertical"}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">

                    <Form.Item
                        hasFeedback
                        label="İsim"
                        name="name"
                        rules={[{required: true, message: 'Bu alan zorunludur!',} ]}>
                        <Input prefix={<UserOutlined />} placeholder={"İsim giriniz"} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Soyisim"
                        name="surname"
                        rules={[{required: true, message: 'Bu alan zorunludur!',} ]}>
                        <Input prefix={<UserOutlined />} placeholder={"Soyisim giriniz"} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="E-Posta"
                        name="email"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}, { type:"email", message: "E-Posta yanlış!"} ]}>
                        <Input prefix={<MailOutlined />} placeholder={"E-Posta giriniz"} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Bayi Veri Erişimi"
                        name="merchant_access"
                        rules={[{required: false, message: 'Bu alan zorunludur!'}]}>
                        <Select mode={"multiple"} allowClear suffixIcon={<FilterOutlined />} placeholder={"Seçim Yapınız"} options={getSubMerchants} />
                    </Form.Item>


                    <Form.Item
                        hasFeedback
                        label="Durum"
                        name="status"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                        <Select suffixIcon={<FilterOutlined />} placeholder={"Durum seçiniz"} options={[
                            {
                                key: Math.random().toString(),
                                label:"Aktif",
                                value:"active"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Pasif",
                                value:"passive"
                            }
                        ]} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Bölge"
                        name="timezone"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                        <Select
                            placeholder={"Seçim yapınız"}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            options={getTimeZones?.map(item => ({ value: item, label: item }))} />
                    </Form.Item>



                    <Form.Item
                        hasFeedback
                        label="Kimlik Doğrulama"
                        name="2fa_status"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                        <Select placeholder={"Durum seçiniz"} options={[
                            {
                                key: Math.random().toString(),
                                label:"Aktif",
                                value:"active"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Pasif",
                                value:"passive"
                            }
                        ]} />
                    </Form.Item>


                    <Form.Item
                        hasFeedback
                        label="Rol"
                        name="role"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                        <Select suffixIcon={<FilterOutlined />} placeholder={"Yetki seçiniz"} options={[
                            {
                                key: Math.random().toString(),
                                label:"Sistem Yöneticisi",
                                value:"root"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Yönetici",
                                value:"admin"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Bayi",
                                value:"merchant"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Personel",
                                value:"agent"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Finans",
                                value:"finance"
                            },
                        ]} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Çoklu Sekme"
                        name="multiple_tab"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                        <Select suffixIcon={<FilterOutlined />} placeholder={"Çoklu Sekme"} options={[
                            {
                                key: Math.random().toString(),
                                label:"Aktif",
                                value:"active"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Pasif",
                                value:"passive"
                            }
                        ]} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Mesai Saatleri"
                        name="shift"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                        <TimePicker.RangePicker style={{width:"100%"}} />
                    </Form.Item>

                </Form>
            </Card>


            <Card style={{marginTop:15}} title={"Şifre Ayarı"} extra={<Button loading={getLoading} icon={<ReloadOutlined />} type="primary" onClick={()=>PersonalUpdateForm.submit()}  block> Güncelle </Button>}>
                <Form
                    disabled={getLoading}
                    form={PersonalUpdateForm}
                    name="PersonalUpdateForm"
                    layout={"vertical"}
                    onFinish={onFinishPassword}
                    onFinishFailed={onFinishFailedPassword}
                    autoComplete="off">

                    <Form.Item
                        hasFeedback
                        label="Şifre"
                        name="password"
                        rules={[{required: true, message: 'Bu alan zorunludur!'},
                            {
                                validator: async () => {
                                    return level >= 1 ? Promise.resolve() : Promise.reject("Şifre güvenliği düşük");
                                },
                                message: "Güçlü bir şifre giriniz"
                            }]}>
                        <PasswordInput prefix={<KeyOutlined />} onLevelChange={setLevel} />
                    </Form.Item>

                </Form>
            </Card>

        </div>
    </>)

};


export default TeamSettingsMerchant;