import React from "react";

const validateEmail = (email) => {
    return String(email)
        .match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

};

const showDatePass = (startDate) => {
    // Şu anki tarih ve saat
    var suAn = new Date();

    // Başlangıç tarihini alınan parametre olarak ayarla
    var baslangic = new Date(startDate);

    // Zaman farkını hesapla (milisaniye cinsinden)
    var zamanFarki = suAn - baslangic;

    // Milisaniyeden saniyeye çevir
    var saniye = Math.floor(zamanFarki / 1000);

    // Saniyeden dakikaya çevir
    var dakika = Math.floor(saniye / 60);
    saniye = saniye % 60;

    // Dakikadan saate çevir
    var saat = Math.floor(dakika / 60);
    dakika = dakika % 60;

    return {
        saat,
        dakika,
        saniye
    }
}

const PaymentColors = {
    AZN: "processing",
};

const PaymentStatusColor = {
    payment_success: "success",
    payment_failed: "error",
    payment_waiting_room: "processing",
    payment_in_room: "info",
    payment_on_process: "warning",
};


export {
    validateEmail,
    showDatePass,
    PaymentColors,
    PaymentStatusColor
}