import React, {forwardRef, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Avatar, List, message, Segmented} from "antd";
import MerchantLiveTransactionsCharts from "../../Components/MerchantLiveTransactionsCharts";
import CountUp from "react-countup";
import MerchantStaticCharts from "../../Components/MerchantStaticCharts";
import {useRecoilState} from "recoil";
import {SocketMerchantReceiveTransactionUpdate} from "../../Storage/SocketMerchantReceiveTransactionUpdate";
import {ApiPost} from "../../Supports/ApiManager";


const HomeScreen = () => {

    const [getStatic,setStatic]                 = useState("today");
    const [getSocketReceive,setSocketReceive]   = useRecoilState(SocketMerchantReceiveTransactionUpdate);

    return(<>
        <div style={{padding:15}}>


            <Row gutter={15}>


                {
                    /*
                     <Col xs={24} sm={24}  md={24} lg={24} xl={24} xxl={24} style={{padding:10}}>
                    <Card title={"Sayısal Monitör"} extra={  <Segmented default={getStatic} value={getStatic} onChange={e => setStatic(e)}   options={[{label:"Bugün",value:"today"},{label:"Genel",value:"general"}]} />} bodyStyle={{minHeight:370,paddingTop:0}}>

                        { getStatic === "today" && <MerchantStaticCharts today /> }
                        { getStatic === "general" && <MerchantStaticCharts general /> }

                    </Card>
                </Col>
                    * */
                }
                <Col xs={24} sm={24} md={24}  lg={24} xl={24} xxl={24} style={{padding:10}}>
                    <Card title={"Canlı Monitor"} bodyStyle={{minHeight:370}} >
                        <MerchantLiveTransactionsCharts live/>
                    </Card>
                </Col>
                <Col xs={24} sm={24}  md={24} lg={24} xl={24} xxl={24} style={{padding:10}}>
                    <Card title={"İşlem Akışı (Transaction)"} bodyStyle={{minHeight:370,paddingTop:0}}>
                        <MerchantLiveTransactionsCharts transaction/>
                    </Card>
                </Col>

            </Row>
        </div>
    </>)

};


export default HomeScreen;