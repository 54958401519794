import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {createBrowserRouter, Navigate, RouterProvider, Outlet, useRouteError, useNavigate, useLocation} from "react-router-dom";
import Initial from "./Secure/Initial";
import AuthScreen from "./AuthScreen";
import ErrorPageRouter from "./Screens/Respose/ErrorPageRouter";
import PaymentScreen from "./Screens/Merchant/PaymentScreen";
import {ConfigProvider, theme, Result, Typography, App as AppAnt} from "antd";
import {ColorStorageValue} from "./Storage/ColorStorage";
import Login from "./Screens/Auth/Login";
import { CloseCircleOutlined } from '@ant-design/icons';
import HomeScreen from "./Screens/Merchant/HomeScreen";
import {AuthControlStorage} from "./Storage/AuthControlStorage";
import trTr from 'antd/locale/tr_TR';
import AdminHomeScreen from "./Screens/Merchant/Admin/AdminHomeScreen";
import PaymentGatewayController from "./Screens/Payment/PaymentGatewayController";
import "./i18n";
import FinanceScreen from "./Screens/Merchant/FinanceScreen";

function Tr2En(text){
    var Maps = {
        "İ":"I","Ş":"S","Ç":"C","Ğ":"G","Ü":"U","Ö":"O",
        "ı":"i","ş":"s","ç":"c","ğ":"g","ü":"u","ö":"o"
    };
    Object.keys(Maps).forEach(function(Old){
        text    = text.replace(Old,Maps[Old]);
    });
    return text;
}
window.trToEn = Tr2En;


const BubbleError = () => {
    const error                 = useRouteError();
    const { Paragraph, Text }   = Typography;

    return <>
        <div style={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Result
                status="500"
                title="500"
                subTitle="HATA, Bir hata oluştu. Aşağıdaki hata kodunu 'Geliştirici Konsolu' üzerinden bakabilirsiniz.">
                <div className="desc">
                    <Paragraph>
                        <Text  strong style={{fontSize: 16, }}> Hata Açıklamaları: </Text>
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className="site-result-demo-error-icon" /> {error.statusText || error.message}
                    </Paragraph>
                </div>
            </Result>
        </div>
    </>
};


const App = () => {


    const [getContent,setContent]               = useState(null);
    const getColor                              = useRecoilValue(ColorStorageValue);
    const [getAuth,setAuth]                     = useRecoilState(AuthControlStorage);


    useEffect(() => {

        setContent([
            {
                path: "/payment/:transaction_id",
                element: <PaymentGatewayController />,
                errorElement: <BubbleError />,
            },
            {
                path: "/app",
                element: <Initial app> <AuthScreen/> </Initial>,
                errorElement: <BubbleError />,
                children:[
                    {
                        path: "",
                        element: <HomeScreen/>,
                        errorElement: <BubbleError />,
                    },
                    {
                        path: "payments",
                        element: <PaymentScreen/>,
                        errorElement: <BubbleError />,
                    },
                    {
                        path: "finance",
                        element: <FinanceScreen/>,
                        errorElement: <BubbleError />,
                    },
                    {
                        path: "admin",
                        element: <AdminHomeScreen/>,
                        errorElement: <BubbleError />,
                    },
                    {
                        path: "*",
                        element: <ErrorPageRouter/>,
                        errorElement: <BubbleError />,
                    },
                ]
            },
            {
                path: "/auth/login",
                element: <Initial login> <Login/> </Initial>,
                errorElement: <BubbleError />
            },
            {
                path: "*",
                element: <Navigate to="/auth/login" replace />,
                errorElement: <BubbleError />,
            },
        ])

    },[getAuth])


    return (
        <ConfigProvider locale={trTr} theme={{ hashed: false, algorithm: getColor === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm }} >
            <AppAnt>
                { getContent !== null && <RouterProvider  router={createBrowserRouter(getContent)} />}
            </AppAnt>
        </ConfigProvider>
    );


};


export default App;