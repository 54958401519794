import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Select,
  Form,
  Table,
  Tag,
  Row,
  Col,
  Space,
  Button,
  App,
  Result,
  Drawer,
} from "antd";
import { ApiPost } from "../../../Supports/ApiManager";
import {
  PaymentColors,
  showDatePass,
} from "../../../Helper";
import { FilterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  SaveOutlined,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { SocketMerchantReceiveTransactionUpdate } from "../../../Storage/SocketMerchantReceiveTransactionUpdate";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { isMobile } from "react-device-detect";
import PaymentDetails from "./PaymentDetails";





const WaitingRoomSelectMerchantKey = "WAITINGROOM_SELECT_MERCHANTS";

const WaitingRoom = ({ onSuccess }) => {
  const [getSocketReceive, setSocketReceive] = useRecoilState(
    SocketMerchantReceiveTransactionUpdate
  );
  const { message, notification, modal } = App.useApp();

  const [getLoading, setLoading] = useState(false);
  const [getRecords, setRecords] = useState([]);
  const [getActionBtnID, setActionBtnID] = useState(null);
  const [openDetailsTransaction, setDetailsTransaction] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const getSelectMerchantsStorage = () =>
    JSON.parse(localStorage.getItem(WaitingRoomSelectMerchantKey) ?? "[]");

  const [getMerchants, setMerchants] = useState([]);
  const [getSelectMerchants, setSelectMerchants] = useState(
    getSelectMerchantsStorage()
  );

  const [getBlinkAction, setBlinkAction] = useState(false);
  const [getAudio, setAudio] = useState(false);
  const getAudioEffect = useRef(
    typeof Audio !== "undefined" ? new Audio("/bip.mp3") : null
  );

  useEffect(() => {
    ApiPost("/merchant/payment/merchants")
      .then((response) => {
        if (response.status !== "success") {
          throw new Error(response.message);
        }

        setMerchants(response.result);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message === undefined
            ? error.message
            : error?.response?.data?.message
        );
        console.error("Error : ", error);
      });
  }, []);

  // useEffect(()=>{

  //     if(window.localStorage.getItem("notification_audio") === null){
  //         window.localStorage.setItem("notification_audio","passive");
  //         setAudio(false);
  //     }

  //     getAudioEffect.loop     = false;
  //     getAudioEffect.autoplay = false;

  // },[getAudio])

  // useEffect(()=>{

  //     if(["active","passive"].includes(window.localStorage.getItem("notification_audio"))){
  //         if(window.localStorage.getItem("notification_audio") === "active"){
  //             setAudio("active");
  //         }
  //         if(window.localStorage.getItem("notification_audio") === "passive"){
  //             setAudio(false);
  //         }
  //     }

  // },[])

  // useEffect(()=>{

  //     try{
  //         if(getAudioEffect !== null){
  //             if(getAudio && getBlinkAction === true){
  //                 getAudioEffect.current.currentTime  = 0;
  //                 getAudioEffect.current.play().catch(error => {
  //                     console.info("İşlem Güncellemesi, Bildirim sesi almak için sesi açınız yada ekranda mouse işlemi yapınız.",error);
  //                 });
  //                 setBlinkAction(false);
  //             }
  //         }
  //     }catch (e){
  //         console.error("Bildirim Sesi Hatası : ",e);
  //     }

  // },[getBlinkAction])

  // Update network status
  const handleStatusChange = () => {
    setIsOnline(navigator.onLine);
    if (navigator.onLine) {
      getData();
    } else {
      console.log("Network connection failed");
    }
  };

  useEffect(() => {
    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    window.addEventListener("focus", handleStatusChange);
    return () => {
      window.removeEventListener("focus", handleStatusChange);
    };
  }, []);

  const inRoomAction = (transaction_id) => {
    setActionBtnID(transaction_id);

    ApiPost(`/payment/${transaction_id}/status/update`, {
      status: "payment_on_process",
    })
      .then((response) => {
        if (response.status !== "success") {
          throw new Error(response.message);
        }

        message.success(response.message);
        setActionBtnID(null);

        onSuccess(true);

        // openDetailsFn(transaction_id);

        message.destroy();
        message.success("İşlemi üzerinize aldınız", 1500);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message === undefined
            ? error.message
            : error?.response?.data?.message
        );
        setActionBtnID(null);
        console.error("Error : ", error);
      });
  };

  const columns = useCallback(
    [
      {
        title: "Bayi",
        dataIndex: "merchant",
        align: "left",
        render: (_, { merchant }) => merchant?.title,
      },
      {
        title: "Kullanıcı Adı",
        dataIndex: "client_username",
        align: "left",
        width: 100,
        render: (_, { client_username }) => client_username,
      } /*

        {
            title: 'Kullanıcı E-Posta',
            dataIndex: 'client_email',
            align: 'left',
            render: (_,{client_email}) => {
                if(validateEmail(client_email)){
                    return <Tag color={"success"}>{client_email}</Tag>
                }else{
                    return <Tag color={"error"}>Bulunamadı</Tag>
                }
            }
        },
        */,
      {
        title: "Method",
        dataIndex: "method",
        align: "left",
        render: (_, { method }) => (
          <Space>
            {method.label}{" "}
            <div
              style={{
                width: 15,
                height: 15,
                borderRadius: 100,
                background: method.value.includes("WITHDRAW") ? "red" : "green",
              }}
            ></div>{" "}
          </Space>
        ),
      },
      {
        title: "İçerik",
        dataIndex: "table_view",
        align: "left",
        render: (_, { table_view }) =>
          table_view.map((item) => {
            if (
              item.label === "Banka" &&
              ![null, "", " "].includes(item.value)
            ) {
              return (
                <img
                  src={`/banks/${window
                    .trToEn(item.value.replace(" ", ""))
                    .replace(/\s/g, "")}.svg`}
                  style={{ width: 100, height: "fit-content" }}
                />
              );
            } else {
              return (
                <Tag color={"black"}>{`${item.label} : ${item.value}`}</Tag>
              );
            }
          }),
      },
      {
        title: "Tutar",
        dataIndex: "amount",
        render: (_, { amount, currency }) => {
          const formatter = new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: currency.value,
          });
          return (
            <Space>
              <Tag
                color={PaymentColors[currency.value] ?? "#000000"}
                style={{ border: "1px dashed purple" }}
              >
                {formatter.format(amount)}
              </Tag>
            </Space>
          );
        },
      } /*
        {
            title: 'Durum',
            dataIndex: 'status',
            render: (_,{status}) => {

                return <Space>
                    <Tag color={PaymentStatusColor[status.value] ?? "black"} style={{border: "1px dashed purple"}}>{status.label}</Tag>
                </Space>;
            }
        },*/,
      {
        title: "İşlem Zamanı",
        dataIndex: "creation_date",
        render: (_, { creation_date }) => dayjs(creation_date).format("HH:mm"),
      },
      {
        title: "İşlemler",
        dataIndex: "id",
        align: "center",
        render: (_, { transaction_id }) => {
          return (
            <>
              <Button
                onClick={() => {
                  message.success("İşleme Alındı");
                  inRoomAction(transaction_id);
                }}
                block
                loading={getActionBtnID === transaction_id}
                type='primary'
                icon={<SaveOutlined />}
              >
                İşleme Al
              </Button>
            </>
          );
        },
      },
    ],
    [getActionBtnID, getMerchants]
  );

  const getData = () => {
    setLoading(true);
    ApiPost("/payment/list/wait-room", {
      merchants: getSelectMerchantsStorage(),
    })
      .then((response) => {
        if (response.status !== "success") {
          throw new Error(response.message);
        }

        // const oldRecordIds = getRecords.map((x) => x.id);
        // const newRecordIds = [];

        setRecords([]);
        response.result.map((item) => {
          // if(!oldRecordIds.includes(item.id)) {
          //     newRecordIds.push(item.id);
          // }
          return setRecords((getRecords) => [...getRecords, item]);
        });

        // if(newRecordIds.length > 0) {
        //     setBlinkAction(true);
        // }

        setLoading(false);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message === undefined
            ? error.message
            : error?.response?.data?.message
        );
        setLoading(false);
        console.error("Error : ", error);
      });
  };

  useEffect(getData, [getSocketReceive, getSelectMerchants]);

  const closeDetailsFn = () => {
    setOpenDetails(false);
    setDetailsTransaction(null);
  };

  const openDetailsFn = (transaction_id) => {
    setDetailsTransaction(transaction_id);
    setOpenDetails(true);
  };

  return (
    <>
      <>
        {!isMobile && (
          <Table
            // loading={getLoading}
            style={{ width: "100%" }}
            scroll={{ x: 1000 }}
            size={"small"}
            columns={columns}
            dataSource={getRecords}
            bordered
            rowKey={"id"}
            key={"id"}
            title={() => (
              <>
                <div style={{ display: "flex", width: "100%" }}>
                  <Space>
                    {Object.keys(PaymentColors).map((item) => (
                      <Tag
                        key={Math.random().toString()}
                        color={PaymentColors[item] ?? "#000000"}
                      >
                        {item}
                      </Tag>
                    ))}
                  </Space>
                  <div style={{ width: "100%" }}>
                    <Form name='horizontal_login' layout='vertical'>
                      <Row gutter={25}>
                        <Col span={6}>
                          <Form.Item
                            name='merchant'
                            style={{ marginBottom: "0" }}
                            initialValue={getSelectMerchants}
                          >
                            <Select
                              placeholder='Choose Merchant'
                              options={getMerchants}
                              mode={"multiple"}
                              suffixIcon={<FilterOutlined />}
                              onChange={(ids) => {
                                setSelectMerchants(ids);
                                localStorage.setItem(
                                  WaitingRoomSelectMerchantKey,
                                  JSON.stringify(ids)
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </>
            )}
            pagination={false}
          />
        )}

        {isMobile && (
          <div style={{ width: "100%" }}>
            {getRecords.length > 0 &&
              getRecords.map((item) => {
                return (
                  <div
                    style={{
                      marginBottom: "10px",
                      border: "1px solid #c4b8b8",
                      padding: "6px",
                      borderRadius: "6px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    {columns.map((colItem) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          margin: "6px 0px",
                          background: "#f3f3f3",
                          borderRadius: 6,
                        }}
                      >
                        <div
                          style={{
                            padding: "3px 15px",
                            width: 140,
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 10,
                          }}
                        >
                          {colItem["title"]}
                        </div>
                        <div
                          style={{
                            padding: "3px 15px",
                            flex: "auto",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 10,
                          }}
                        >
                          {colItem["render"](item, item)}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}

            {getRecords.length === 0 && (
              <>
                <Result status='warning' title='İşlem Bulunamadı' />
              </>
            )}
          </div>
        )}

        <Drawer
          title='İşlem Detayı'
          width={650}
          placement='right'
          onClose={closeDetailsFn}
          bodyStyle={{ padding: 10 }}
          open={openDetails}
        >
          {openDetailsTransaction !== null && (
            <PaymentDetails
              onClose={closeDetailsFn}
              transaction={{ transaction_id: openDetailsTransaction }}
            />
          )}
        </Drawer>
      </>
    </>
  );
};

export default WaitingRoom;
