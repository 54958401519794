import {atom, selector} from "recoil";

const SocketLiveMapState = atom({
    key: 'SocketLiveMapState',
    default: {
        charts_data: [],
        data: [],
    },
});


const SocketLiveMapValue = selector({
    key: 'SocketLiveMapValue',
    get: ({get}) => {
        return get(SocketLiveMapState);
    },
});

export {
    SocketLiveMapState,
    SocketLiveMapValue
}