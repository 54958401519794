import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Drawer, Tabs, Statistic, Card, Col, Row, FloatButton} from "antd";
import WaitingRoom from "./Payment/WaitingRoom";
import OnProcessRoomSingle from "./Payment/OnProcessRoomSingle";
import TransactionHistory from "./Payment/TransactionHistory";
import StickyBox from 'react-sticky-box';
import isMobile from "is-mobile";
import {StockOutlined} from "@ant-design/icons";
import {ApiPost} from "../../Supports/ApiManager";
import CountUp from 'react-countup';
import {useRecoilState} from "recoil";
import {SocketLiveTransactionsState} from "../../Storage/SocketLiveTransactions";
import {SocketMerchantReceiveTransactionUpdate} from "../../Storage/SocketMerchantReceiveTransactionUpdate";

const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox
        offsetTop={0}
        offsetBottom={20}
        style={{
            zIndex: 1,
        }}>
        <DefaultTabBar {...props} />
    </StickyBox>
);


const formatter = (value) => <CountUp end={value} separator="," />;


const colSizeSpannerDesktop = {
    1: 24,
    2: 12,
    3: 8,
    4: 6,
    5: 6,
    6: 6,
    7: 6
}

const PaymentScreen = () => {

    const [getStatic,setStatic]                 = useState([]);
    const [getHistoryDrawer,setHistoryDrawer]   = useState(false);
    const [getDailyStatic,setDailyStatic]   = useState(false);
    const [getActiveTab,setActiveTab]   = useState("1");
    const [getSocketReceive,setSocketReceive]   = useRecoilState(SocketMerchantReceiveTransactionUpdate);


    useEffect(() => {

        ApiPost("/payment/agent/dashboard")
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                setStatic([]);
                response.result.map(item => {
                    setStatic(getStatic => [...getStatic,item]);
                });

            })
            .catch(error => {
                console.log(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })


    },[getSocketReceive]);


    const GetStaticBoardMobile = () =>  (
        <div  style={{  padding: "5px 25px", borderRadius: "10px" }}>
            <Row gutter={0}>

                {
                    getStatic.map(item => (
                        <>

                            <Col span={24} style={{paddingRight:15, display:"flex", justifyContent:"center", alignItems:"center", marginTop:10, marginBottom:5}}> <span style={{fontWeight:700}}>{item.title}</span> </Col>
                            <Col span={24} style={{marginBottom: getStatic.length > 1 ?  10 : 0}}>
                                <Row >
                                    <Col span={8} style={{padding:"4px 0px",textAlign: "center", color: "white", background: "#76d90c", borderTopLeftRadius: "10px", borderTopRightRadius: "0px"}}>Başarılı</Col>
                                    <Col span={8} style={{padding:"4px 0px",textAlign: "center", color: "white", background: "#cc0808", borderTopLeftRadius: "0px", borderTopRightRadius: "0px"}}>Başarısız</Col>
                                    <Col span={8} style={{padding:"4px 0px",textAlign: "center", color: "white", background: "#3b3b3b", borderTopLeftRadius: "0px", borderTopRightRadius: "10px"}}>Toplam</Col>
                                    <Col span={8} style={{padding:"6px 0px", fontWeight:700 ,textAlign: "center", color: "white", background: "#58a605", borderBottomLeftRadius: "10px", borderBottomRightRadius: "0px"}}> {item.values.count.success}</Col>
                                    <Col span={8} style={{padding:"6px 0px", fontWeight:700 , textAlign: "center", color: "white", background: "#a60404", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"}}> {item.values.count.failed}</Col>
                                    <Col span={8} style={{padding:"6px 0px", fontWeight:700 , textAlign: "center", color: "white", background: "#111111", borderBottomLeftRadius: "0px", borderBottomRightRadius: "10px"}}> {item.values.count.failed  + item.values.count.success}</Col>
                                </Row>
                            </Col>

                        </>
                    ))
                }
            </Row>
        </div>
    );

    const GetStaticBoard = () => (
            <Row gutter={25}>

                { getStatic.map(item => (

                    <Col span={colSizeSpannerDesktop[getStatic.length]}>
                        <div style={{
                            background: "rgba(0, 0, 0, 0.13)",
                            padding: "5px 5px",
                            borderRadius: "10px",
                            marginTop: "10px"
                        }}>
                            <Row>

                                <Col span={6} style={{
                                    paddingRight: 15,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}> <span style={{fontWeight: 700}}>{item.title}</span> </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={8} style={{
                                            textAlign: "center",
                                            color: "white",
                                            background: "#76d90c",
                                            borderTopLeftRadius: "10px",
                                            borderTopRightRadius: "0px"
                                        }}>Başarılı</Col>
                                        <Col span={8} style={{
                                            textAlign: "center",
                                            color: "white",
                                            background: "#cc0808",
                                            borderTopLeftRadius: "0px",
                                            borderTopRightRadius: "0px"
                                        }}>Başarısız</Col>
                                        <Col span={8} style={{
                                            textAlign: "center",
                                            color: "white",
                                            background: "#3b3b3b",
                                            borderTopLeftRadius: "0px",
                                            borderTopRightRadius: "10px"
                                        }}>Toplam</Col>
                                        <Col span={8} style={{
                                            textAlign: "center",
                                            color: "white",
                                            background: "#58a605",
                                            borderBottomLeftRadius: "10px",
                                            borderBottomRightRadius: "0px"
                                        }}> {item.values.count.success}</Col>
                                        <Col span={8} style={{
                                            textAlign: "center",
                                            color: "white",
                                            background: "#a60404",
                                            borderBottomLeftRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        }}> {item.values.count.failed}</Col>
                                        <Col span={8} style={{
                                            textAlign: "center",
                                            color: "white",
                                            background: "#111111",
                                            borderBottomLeftRadius: "0px",
                                            borderBottomRightRadius: "10px"
                                        }}> {item.values.count.failed + item.values.count.success}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    ))}

            </Row>
        )
    ;

    return (<>

        <div style={{padding: 15, paddingTop: 0, display: "flex", flexDirection: "column", height:"100%"}}>


            {
                /*
                *
                *
                <div style={{ height: "50px", paddingLeft: "15px", display: "flex", alignItems: "center", paddingBottom: "16px", justifyContent: isMobile() ? "center" : "flex-end"}}>
                    <Button type={"primary"} danger shape={"round"}   icon={<FieldTimeOutlined />} onClick={() => setHistoryDrawer(true)} >Geçmiş İşlemler</Button>
                </div>
                *
                *


                <React.Fragment>
                    <Collapse
                        style={{marginTop:15, marginBottom:15, padding:0}}
                        items={[
                        {
                            key: '1',
                            label: "İşlem Hareket Bilgileri",
                            children:  isMobile()  ? <GetStaticBoardMobile /> : <GetStaticBoard />
                        }
                    ]} />
                </React.Fragment>

                  * */
            }

            {
                isMobile() &&
                <Button icon={<StockOutlined />} style={{marginTop:10}} onClick={ () => setDailyStatic(true)}>
                    Verilere Git
                </Button>
            }

            {
                !isMobile() &&
                    <FloatButton icon={<StockOutlined />} style={{marginTop:10}} onClick={ () => setDailyStatic(true)} shape={"circle"} />
            }


            <Tabs
                style={{height:"100%"}}
                renderTabBar={renderTabBar}
                defaultActiveKey={getActiveTab}
                activeKey={getActiveTab}
                onChange={(getTab) => setActiveTab(getTab)}
                items={[
                    {
                        key: '1',
                        label: 'Talepler',
                        children: <WaitingRoom onSuccess={() => {
                            setActiveTab("2")
                        }}/>,
                    },
                    {
                        key: '2',
                        label: 'İşlemlerim',
                        children: <OnProcessRoomSingle/>,
                    }
                ]}/>

        </div>


        <Drawer
            title="Geçmiş İşlemler"
            height={"100%"}
            placement="bottom"
            onClose={() => setHistoryDrawer(false)}
            open={getHistoryDrawer}>
            {getHistoryDrawer && <TransactionHistory /> }
        </Drawer>

        <Drawer
            title="Günlük İşlemler"
            height={isMobile() ? "100%" : 350}
            placement="right"
            bodyStyle={{padding:0}}
            onClose={() => setDailyStatic(false)}
            open={getDailyStatic}>
            <GetStaticBoardMobile/>
        </Drawer>



    </>)

};


export default PaymentScreen;