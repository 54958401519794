import React, {forwardRef, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Avatar, List, message, Segmented, Tabs} from "antd";
import {useRecoilState} from "recoil";
import {AuthAccount} from "../../../Storage/AuthAccount";
import TeamManager from "./Screens/Admin/TeamManager";
import CallbackHistory from "./Screens/Admin/CallbackHistory";
import MerchantTransactionHistoryAdmin from "./Screens/Admin/TransactionHistoryAdmin";
import MerchantManager from "./Screens/MerchantAndSystem/MerchantManager";
import ErrorPageRouter from "../../Respose/ErrorPageRouter";
import TMManagerAdmin from "./Screens/Admin/TransferMethodManager/TMManagerAdmin";


const AdminHomeScreen = () => {

    const [getTabs,setTabs]         = useState([])
    const [getAccount,]             = useRecoilState(AuthAccount);

    useEffect(()=>{
        if(getAccount !== null){

            setTabs([]);

            if(getAccount?.role?.value === "root") {
                setTabs(current => [...current,{
                    key: Math.random().toString(),
                    label:"Personel Yönetimi",
                    children: <TeamManager/>
                }]);
            }

            setTabs(current => [...current,{
                key: Math.random().toString(),
                label:"Method Yönetimi",
                children: <TMManagerAdmin/>
            }]);

            setTabs(current => [...current,{
                key: Math.random().toString(),
                label:"Callback İzleme",
                children: <CallbackHistory/>
            }]);

            setTabs(current => [...current,{
                key: Math.random().toString(),
                label:"İşlem Geçmişi",
                children: <MerchantTransactionHistoryAdmin/>
            }]);


            if(["root","merchant"].includes(getAccount?.role?.value)){
                setTabs(current => [...current,{
                    key: Math.random().toString(),
                    label:"Müşteri Yönetimi",
                    children: <MerchantManager/>
                }]);
            }

        }
    },[getAccount])


    if(["root","admin","merchant"].includes(getAccount?.role?.value)){
        return(
            <div style={{padding:15, paddingTop:5}}>
                <Tabs  items={getTabs} />
            </div>
        )
    }else{
        return(
            <div style={{padding:15, paddingTop:5}}>
                <ErrorPageRouter/>
            </div>
        )
    }
};


export default AdminHomeScreen;