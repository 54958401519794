import React, {forwardRef, useEffect, useRef, useState} from "react";
import { Chart } from "frappe-charts"
import {useRecoilState} from "recoil";
import {SocketLiveMapState} from "../Storage/SocketLiveMap";
import {Row, Col, Card, Avatar, Button, Result} from "antd";
import { FundOutlined, ArrowRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
 import { useAutoAnimate } from '@formkit/auto-animate/react'


const MerchantLiveTransactionsCharts = (props) => {

    const [getSocketLiveMap,setSocketLiveMap]       = useRecoilState(SocketLiveMapState);
    const [getChartsRef,setChartsRef]               = useState(null);
    const [getLastFiveAction,setLastFiveAction]     = useState([]);
    const [parent, enableAnimations]                = useAutoAnimate(/* optional config */)

    useEffect(()=>{

        const chart = new Chart("#chart", {
            data: {
                labels: getSocketLiveMap.charts_data.map(item => item.label),
                datasets: [
                    {
                        name: "Toplam",
                        chartType: 'bar',
                        values: getSocketLiveMap.charts_data.map(item => item.value)
                    },
                ],

                yMarkers: [{ label: "100 Kullanıcı", value: 100,  options: { labelPos: 'left' }}],
                yRegions: [{ label: "Maksimum Gelen", start: -10, end: 50,  options: { labelPos: 'right' }}]
            },
            type: 'axis-mixed',
            height: 300,
            colors: ['purple', '#ffa3ef', 'light-blue'],
            tooltipOptions: {
                formatTooltipX: d => (d + '').toUpperCase(),
                formatTooltipY: d => d + ' İstek',
            }
        })
        setChartsRef(chart);

    },[])

    useEffect(()=>{

        if(getChartsRef !== null){
            getChartsRef.update( {
                labels: getSocketLiveMap.charts_data.map(item => item.label),
                datasets: [
                    {
                        name: "Toplam",
                        chartType: 'bar',
                        values: getSocketLiveMap.charts_data.map(item => item.value)
                    },
                ],
                yMarkers: [{ label: "100 Kullanıcı", value: 100,  options: { labelPos: 'left' }}],
                yRegions: [{ label: "Maksimum Gelen", start: -10, end: 50,  options: { labelPos: 'right' }}]
            });
        }

        setLastFiveAction(Object.values(getSocketLiveMap.data).slice(-4).sort((a,b) => a.time - b.time ? -1 : 1))


    },[getSocketLiveMap]);

    useEffect(() => {
        enableAnimations(true);
    }, [parent])


    if(props?.live){
        return (<div id="chart" />);
    }

    if(props?.transaction){
        return (<>

            {
                getLastFiveAction.length === 0 &&
                <Result
                    status="success"
                    title="Canlı Ödemeler"
                    subTitle="Bu alanda ödeme hareketlerini anlık olarak görünrülemektesiniz."
                />
            }
            {
                getLastFiveAction.length > 0 &&
                <ul style={{maxHeight:300, listStyleType:"none",padding:0}} ref={parent}>
                    {getLastFiveAction.map((item) => (
                        <li key={(Math.random().toString())} style={{background: "rgba(243,243,243,0.2)", borderRadius: "6px", maxHeight:80,  minHeight:80, marginBottom: "6px", padding: "10px"}}>
                            <Row>
                                <Col style={{width:70, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <FundOutlined />
                                </Col>
                                <Col flex={"auto"} style={{width:"min-content"}}>
                                    <div style={{fontSize:14, fontWeight:500}}>{item.transaction_id}</div>
                                    <div style={{fontSize:11}}>{dayjs(item.time).format("H:mm:s")}</div>
                                </Col>
                            </Row>
                        </li>
                    ))}
                </ul>

            }

        </>)
    }

};


export default MerchantLiveTransactionsCharts;