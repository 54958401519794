import React, {useState} from "react";
import {Form, Checkbox, Input, Button, App} from "antd";
import {Player} from "@lottiefiles/react-lottie-player";
import getAnimate from "../../Assets/lottiefiles/paymentAnimV4.json";
import {useRecoilState, useRecoilValue} from "recoil";
import {ApiPost} from "../../Supports/ApiManager";
import {useNavigate} from "react-router-dom";
import {AuthControlStorage} from "../../Storage/AuthControlStorage";



const Login = () => {

    const { message, notification, modal }                      = App.useApp();

    const navigate                          = useNavigate();
    const [get2FA,set2FA]                   = useState(false);
    const [initLoading,setLoading]          = useState(false);
    const [getAuth,setAuth]                 = useRecoilState(AuthControlStorage);

    const onFinish = values => {

        setLoading(true);

        ApiPost("/auth/login",{
            username: values.username,
            password: values.password,
            ["2fa"]: get2FA ? values["2fa"] : "login"
        })
            .then(async response => {

                if(response.status !== "success"){
                    throw new Error(response.message);
                }

                window.localStorage.clear();

                window.localStorage.setItem("token",response.result.token)

                ApiPost("/merchant/view")
                    .then(responseView => {

                        if(responseView.status !== "success"){
                            throw new Error(responseView.message);
                        }

                        window.localStorage.setItem("merchant_id",responseView.result.merchant_id)

                        setLoading(false);
                        setAuth(true);
                        navigate("/app");

                    })
                    .catch(error => {
                        message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                        navigate("/auth/login");
                    })

            })
            .catch(error => {
                setLoading(false);
                if(error?.response?.data?.result !== undefined){
                    if(error?.response?.data?.result["2fa"]){
                        set2FA(true)
                    }else{
                        message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                        navigate("/auth/login");
                    }
                }else{
                    message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                    navigate("/auth/login");
                }
            })


    };

    const onFinishFailed = errorInfo => {
        console.error('Failed:', errorInfo);
    };

    return(<>

        <div className="login-page">
            <div className="login-box">
                <div className="illustration-wrapper">
                    <Player
                        autoplay
                        loop
                        src={getAnimate}
                        style={{  width: "100%", background:"white" }}>
                    </Player>
                </div>
                <Form
                    disabled={initLoading}
                    autoComplete={"off"}
                    name="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>

                    <p className="form-title">Ödeme Noktası</p>
                    <p>Yetkili Giriş</p>

                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'E-Posta adresinizi giriniz!' },{ type: "email", message: 'E-Posta yanlış!' }]}>
                        <Input
                            autoComplete={"off"}
                            placeholder="E-posta Adresiniz"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Şifrenizi giriniz!' }]}>
                        <Input.Password
                            autoComplete={"off"}
                            placeholder="Şifreniz"
                        />
                    </Form.Item>


                    {
                        get2FA &&
                        <Form.Item
                            name="2fa"
                            rules={[{ required: true, message: 'Doğrulama kodunuzu giriniz!' }]}>
                            <Input
                                autoComplete={"off"}
                                placeholder="Doğrulama Kodu"
                            />
                        </Form.Item>
                    }

                    <Form.Item style={{marginTop:25}}>
                        <Button loading={initLoading} type="primary" htmlType="submit" className="login-form-button">
                            {initLoading ? "Oturum Açılıyor" : "Oturum Aç"}
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </div>

    </>);

};


export default Login