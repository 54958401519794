import React,{useEffect,useState} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './Supports/reportWebVitals';
import {RecoilRoot, useRecoilSnapshot, useRecoilValue} from 'recoil';
import { stopReportingRuntimeErrors } from "react-error-overlay";
import { App as AppAnt } from 'antd';

import App from "./App";
import {ProgressBar} from "react-windows-ui";
import {MemoryRouter} from "react-router-dom";


const ProcessBarScreenFallback = () => <div style={{width: "100%", height: "100vh", display: "flex", placeContent: "center", alignItems: "center"}}><ProgressBar  setProgress={"indeterminate"} /></div>;


ReactDOM.createRoot(document.getElementById('root')).render(

            <RecoilRoot>
                <React.Suspense fallback={<ProcessBarScreenFallback/>}>
                    <App/>
                </React.Suspense>
            </RecoilRoot>
);
