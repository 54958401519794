import {atom, selector} from "recoil";

const SocketLiveTransactionsState = atom({
    key: 'SocketLiveTransactionsState',
    default: [],
});


const SocketLiveTransactionsStateValue = selector({
    key: 'SocketLiveTransactionsStateValue',
    get: ({get}) => {
        return get(SocketLiveTransactionsState);
    },
});

export {
    SocketLiveTransactionsState,
    SocketLiveTransactionsStateValue
}