import {atom} from "recoil";

const SocketMerchantReceiveTransactionUpdate = atom({
    key: 'SocketMerchantReceiveTransactionUpdate',
    default: {
        transaction_id: null,
        merchant_id: null,
        time: null,
    },
});

export {
    SocketMerchantReceiveTransactionUpdate
}