import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Card, Tabs, Form, DatePicker, Upload, Button, Select, Space, Badge, Tag, Table, Input, App,} from "antd";
import 'dayjs/locale/tr';
import {PlusOutlined, PaperClipOutlined, ReloadOutlined, LoadingOutlined} from '@ant-design/icons';
import {ApiPost, ApiPostFile} from "../../../../../Supports/ApiManager";

const SiteSettins = (props) => {

    const { message, notification, modal }                      = App.useApp();

    const [PersonalForm]                                        = Form.useForm();
    const [getApps,setApps]                                     = useState([]);
    const [getLoading,setLoading]                               = useState(false);

    const GetSettingsForm = () => {
        return(
            <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>

                <Form
                    disabled={getLoading}
                    form={PersonalForm}
                    name="PersonalForm"
                    layout={"vertical"}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">

                    <Form.Item
                        hasFeedback
                        label="Başık"
                        name="title"
                        rules={[{required: true, message: 'Bu alan zorunludur!',} ]}>
                        <Input maxLength={50} showCount placeholder={"Başlık giriniz"} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Açıklama"
                        name="description"
                        rules={[{required: true, message: 'Bu alan zorunludur!',} ]}>
                        <Input.TextArea row={3} maxLength={100} showCount placeholder={"Başlık giriniz"} />
                    </Form.Item>


                    <Form.Item
                        hasFeedback
                        label="Method"
                        name="apps"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                        <Select placeholder={"Method seçiniz"} mode="multiple" options={getApps} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Durum"
                        name="live_status"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                        <Select placeholder={"Durum seçiniz"} options={[
                            {
                                key: Math.random().toString(),
                                label:"Aktif",
                                value:"active"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Pasif",
                                value:"passive"
                            }
                        ]} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Markalama Durum"
                        name="brand_status"
                        rules={[{required: true, message: 'Bu alan zorunludur!'}]}>
                        <Select placeholder={"Durum seçiniz"} options={[
                            {
                                key: Math.random().toString(),
                                label:"Aktif",
                                value:"active"
                            },
                            {
                                key: Math.random().toString(),
                                label:"Pasif",
                                value:"passive"
                            }
                        ]} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Markalama domain"
                        name="brand_domain"
                        rules={[{required: true, message: 'Bu alan zorunludur!'},{pattern:"^([a-z0-9-]+\\.)+([a-zA-Z]{2,}|[a-z]{2,}\\.[a-z]{2})$", message: "Sadece küçük harf ve '-' ile yazınız"}]}>
                        <Input  prefix={<PaperClipOutlined />} placeholder={"Başlık giriniz"} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Yatırım Api"
                        name="api_deposit_callback_url"
                        rules={[{required: true, message: 'Bu alan zorunludur!'},{ type:"url", message:"Url Adresi Giriniz"}]}>
                        <Input prefix={<PaperClipOutlined />} placeholder={"Başarılı url giriniz"} />
                    </Form.Item>

                    <Form.Item
                        hasFeedback
                        label="Çekim Api"
                        name="api_withdraw_callback_url"
                        rules={[{required: true, message: 'Bu alan zorunludur!'},{ type:"url", message:"Url Adresi Giriniz"}]}>
                        <Input prefix={<PaperClipOutlined />} placeholder={"Başarısız url giriniz"} />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 8,
                        }}>
                        <Button loading={getLoading} icon={<ReloadOutlined />} type="primary" htmlType="submit" block>
                            Güncelle
                        </Button>
                    </Form.Item>


                </Form>

            </div>
        )
    }


    const uploadProps = {
        multiple: false,
        customRequest({ action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials }) {

            ApiPostFile(`/merchant/${props?.merchantID}/update/logo`,{
                file
            },(percent)=>{
                onProgress({ percent:percent }, file);

            })
                .then(response => {
                    message.success("Logo Güncellendi");
                    onSuccess(response, file);
                    props?.onChange(true);
                })
                .catch(error => {
                    message.error("Yükleme Başarısız");
                    onError(error);
                })

            return {
                abort() {
                    message.error("Yükleme Başarısız");
                },
            };
        },
    };

    const GetSettingLogo = () => {

        return(
            <Upload {...uploadProps}>
                <Button type={"primary"} shape={"round"} block >Logo Seçiniz</Button>
            </Upload>
        )
    }

    const items = [
        {
            key: '1',
            label: 'Profil',
            children: <GetSettingsForm/>
        },
        {
            key: '2',
            label: 'Logo',
            children: <GetSettingLogo/>
        },
    ];

    useEffect(()=>{

        ApiPost(`/merchant/${props?.merchantID}/view`,{ })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }


                PersonalForm.setFieldsValue({
                    title: response.result.title,
                    description: response.result.description,
                    apps: response.result.apps.value,
                    brand_domain: response.result.brand_domain,
                    brand_status: response.result.brand_status.value,
                    live_status: response.result.live_status.value,
                    api_deposit_callback_url: response.result.api_deposit_callback_url,
                    api_withdraw_callback_url: response.result.api_withdraw_callback_url
                });


            }).catch(error =>{
            message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
        })


        ApiPost("/merchant/payment/method",{ })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setApps(response.result);

            }).catch(error =>{
            message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
        })


    },[])

    const onFinish = (values) => {

        setLoading(true);
        ApiPost(`/merchant/${props?.merchantID}/update`,{
            title:                      values.title,
            description:                values.description,
            apps:                       values.apps,
            api_deposit_callback_url:   values.api_deposit_callback_url,
            api_withdraw_callback_url:    values.api_withdraw_callback_url,
            brand_domain:               values.brand_domain,
            live_status:                values.live_status,
            brand_status:               values.brand_status,
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setTimeout(() => {
                    setLoading(false);
                    message.destroy();
                    message.success(response.message);
                    props?.onChange(true);
                },1000)

            }).catch(error =>{
            setLoading(false);
            message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            props?.onChange(false);
        })

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return(<Tabs defaultActiveKey="1" items={items}  />)

};


export default SiteSettins;