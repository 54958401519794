import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {Badge, Button, Col, Drawer, Row, Space, Table, Dropdown, Tag, App, Image} from "antd";
import {useRecoilState} from "recoil";
import {AuthAccount} from "../../../../../Storage/AuthAccount";
import {PaymentColors, PaymentStatusColor, validateEmail} from "../../../../../Helper";
import {SocketMerchantReceiveTransactionStatusUpdate} from "../../../../../Storage/SocketMerchantReceiveTransactionStatusUpdate";
import {ApiPost} from "../../../../../Supports/ApiManager";
import dayjs from "dayjs";
import {DownOutlined, UserAddOutlined, SettingOutlined, HistoryOutlined} from "@ant-design/icons";
import TeamTransactionHistory from "./TeamTransactionHistory";
import TeamSettings from "./TeamSettings";
import PersonalCreate from "./PersonalCreate";
import {callback} from "chart.js/helpers";


const TeamManager = () => {

    const { message, notification, modal }                      = App.useApp();

    const [getStatusReceive,]                                               = useRecoilState(SocketMerchantReceiveTransactionStatusUpdate);
    const [getLoading,setLoading]                                           = useState(false);
    const [getRecords,setRecords]                                           = useState([]);
    const [getDetailsID, setDetailsID]                                      = useState(null);
    const [getDetails, setDetails]                                          = useState({});
    const [getSettingsDrawer, setSettingsDrawer]                            = useState(false);
    const [getHistoryDrawer, setHistoryDrawer]                              = useState(false);
    const [getPersonalCreateDrawer, setPersonalCreateDrawer]                = useState(false);


    const openDetailsFn = (details) => {


        if(["root","merchant","admin"].includes(details?.role?.value)){
            message.warning("Bu kullanıcı admin 'Müşteri Yönetimi' üzerinden düzenlenebilir yada Sistem yöneticisi ile ieltişime geçiniz");
            return;
        }

        setDetailsID(details.id);
        setSettingsDrawer(false);


        ApiPost(`/team/${details.id}/view`)
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setSettingsDrawer(true);
                setDetails(details);

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                console.error("Error : ",error);
            })

    };

    const closeDetailsFn = () => {

        setDetailsID(null);
        setSettingsDrawer(false);

    }

    const openDetailsHistoryFn = (details) => {

        setDetailsID(details.id);
        setHistoryDrawer(true);

    };

    const closeDetailsHistoryFn = () => {

        setDetailsID(null);
        setHistoryDrawer(false);

    };


    const getTeams = () => {

        setLoading(true);
        ApiPost("/teams")
            .then(({status, message, result}) => {

                if(status !== "success"){
                    throw new Error(message);
                }

                setRecords([]);
                result.map(item => setRecords(getRecords => [...getRecords,item]));
                setLoading(false);


            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoading(false);
                console.error("Error : ",error);
            })

    };


    useEffect(getTeams,[])


    const columns = useCallback([
        {
            title: 'İsim',
            dataIndex: 'name',
            align: 'left',
        },
        {
            title: 'Soyisim',
            dataIndex: 'surname',
            align: 'left',
        },
        {
            title: 'E-Posta',
            dataIndex: 'email',
            align: 'left',
            render: (_,{email}) => {
                if(validateEmail(email)){
                    return <Tag color={"success"}>{email}</Tag>
                }else{
                    return <Tag color={"error"}>Bulunamadı</Tag>
                }
            }
        },
        {
            title: 'Kayıt Açan',
            dataIndex: 'admin',
            align: 'left',
            render: (_,{admin}) => {
                if(admin){
                    return <Tag color={"success"}>{`${admin.name} ${admin.surname}`}</Tag>
                }else{
                    return <Tag color={"error"}>Bulunamadı</Tag>
                }
            }
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            render: (_,{status}) => {
                if(status.value === "active"){
                    return <Tag color={"success"}>Aktif</Tag>
                }else{
                    return <Tag color={"error"}>Pasif</Tag>
                }
            }
        },
        {
            title: 'Rol',
            dataIndex: 'role',
            render: (_,{role}) => role.label
        },
        {
            title: 'Kimlik Doğrulama',
            width:150,
            dataIndex: '2fa_key',
            render: (_,item) => (
                <>

                    {item["2fa_status"]["value"] === "active" && (
                        <>

                            <Space style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", alignContent: "center", flexWrap: "nowrap"}}>
                                <Tag style={{width: "90px", textAlign: "center", margin: "0px" }} color={"success"}>AKTİF</Tag>

                                <Image
                                    width={100}
                                    src={item["2fa_key"]["image"]}
                                />
                                {item["2fa_key"]["value"]}
                            </Space>
                        </>
                    )}
                    {item["2fa_status"]["value"] === "passive" &&
                    <Space style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", alignContent: "center", flexWrap: "nowrap"}}>
                        <Tag style={{width: "90px", textAlign: "center", margin: "0px" }} color={"error"}>PASİF</Tag>
                    </Space>}
                </>
            )
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'creation_date',
            render:(_,{creation_date}) => dayjs(creation_date).format("DD/MM/YYYY HH:mm:ss")
        },
        {
            title: 'İşlemler',
            dataIndex: 'transaction_id',
            align: "center",
            render:(_,details) => {

                return (
                    <Space>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        icon: <HistoryOutlined />,
                                        label: "İşlem Geçmişi",
                                        key: '0',
                                        onClick:() => openDetailsHistoryFn(details)
                                    },
                                    {
                                        icon: <SettingOutlined />,
                                        label: "Ayarlar",
                                        key: '1',
                                        onClick:() => openDetailsFn(details)
                                    }
                                ],
                            }}
                            trigger={['click']}>
                            <Button loading={details.id === getDetailsID} type="primary" icon={<DownOutlined />} >
                                İşlem Seçiniz
                            </Button>
                        </Dropdown>


                    </Space>
                )

            }
        },
    ],[]);


    return(
        <div style={{}}>


            <Row>
                <Col span={24}>
                    <Table
                        loading={getLoading}
                        style={{ width:"100%"}}
                        scroll={{x:1300,y:400}}
                        size={"small"}
                        columns={columns}
                        dataSource={getRecords}
                        rowKey={"id"}
                        bordered
                        title={
                            () => <>
                                <Space style={{display:"flex", justifyContent:"end"}}>
                                    <Button onClick={()=>setPersonalCreateDrawer(true)} icon={<UserAddOutlined />} type={"default"} shape={"round"}>Personel Oluştur</Button>
                                </Space>
                            </>}
                        pagination={false}
                    />
                </Col>
            </Row>


            <Drawer
                title="Geçmiş İşlemler"
                height={"90%"}
                placement="bottom"
                onClose={closeDetailsHistoryFn}
                open={getHistoryDrawer}>
                {getHistoryDrawer && <TeamTransactionHistory agentID={getDetailsID}  /> }
            </Drawer>

            <Drawer
                title="Personel Ayarı"
                width={450}
                placement="right"
                onClose={closeDetailsFn}
                open={getSettingsDrawer}>
                {getSettingsDrawer && <TeamSettings agentID={getDetailsID} onChange={() => getTeams()}/> }
            </Drawer>

            <Drawer
                title="Personel Kayıt"
                width={450}
                placement="right"
                onClose={()=>setPersonalCreateDrawer(false)}
                open={getPersonalCreateDrawer}>
                {getPersonalCreateDrawer && <PersonalCreate onChange={() => {
                    setPersonalCreateDrawer(false);
                    getTeams();
                }} /> }
            </Drawer>


        </div>
    )

};


export default TeamManager;