import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Spin, Input, Row, Form, Select, App, DatePicker} from "antd";
import {io} from "socket.io-client";
import success_payment from "../../../Assets/lottiefiles/success_payment.json";
import failed_payment from "../../../Assets/lottiefiles/failed_payment.json";
import wait_payment from "../../../Assets/lottiefiles/wait_payment.json";
import {LoadingOutlined, UserOutlined, KeyOutlined, FilterOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import Settings from "../../../Settings";
import {ApiPostPaymentGateway} from "../../../Supports/ApiManager";
import CreditCardInput from 'react-credit-card-input';
import PaymentMethods from '../../../Assets/methods.png';
import "../../../Assets/public.css"
import {Player} from "@lottiefiles/react-lottie-player";
import dayjs from "dayjs";
import InputMask from 'react-input-mask';

import * as cardValidator from "card-validator";



const PaymentOk = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={success_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_ok")}</div>
    </>
}

const PaymentNO = () => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={failed_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{t("payment_no")}</div>
    </>
}

const PaymentWait = ({message}) => {
    const { t, i18n }                               = useTranslation();

    return <>
        <Player
            autoplay
            loop
            src={wait_payment}
            style={{  width: 200, background:"white", marginTop:40, marginBottom:40 }}>
        </Player>
        <div style={{width: "100%", height: "50px", textAlign: "center", fontSize: "18px", fontWeight: 600, marginBottom: "30px"}}>{message}</div>
    </>
}



const normalize = (value) => value.replace(/\D+/g, "");


const CreditCardInformationComponent = ({getTransaction,getAmount}) => {


    const {message}                         = App.useApp();
    const { t, i18n }                       = useTranslation();
    const [getLoader,setLoader]             = useState(false)
    const [cardForm] = Form.useForm();


    function formatCreditCardNumber(cardNumber) {

        var cleanedNumber = cardNumber.replace(/\s+/g, '').replace(/-/g, '');

        var regex = /([0-9]{1,4})/g;
        var groups = cleanedNumber.match(regex);

        if (groups) {
            return groups.join(' ');
        } else {
            return cardNumber;
        }
    }

    function validateCard(_, value) {

        const { card, isPotentiallyValid, isValid } = cardValidator.number(value);


        if (value ) {
            return (value.length >= 15 ? isValid : isPotentiallyValid)
                ? Promise.resolve()
                : Promise.reject(new Error(t("credit_card_error")));
        } else {
            return Promise.reject(new Error(t("credit_card_error")));
        }
    }

    function validateDate(_, value) {

        var bugun = new Date();
        var girilenTarih = new Date("20" + value.substring(2, 4), value.substring(0, 2) - 1);

        if(girilenTarih < bugun){
            return Promise.reject(new Error(t("credit_card_date_error")));
        } else{
            return Promise.resolve();
        }

    }



    const sendRequest = (values) => {


        setLoader(true);
        ApiPostPaymentGateway(`/public/payment/transaction/on/${getTransaction.transaction_id}/step/take_card`,{
            card_number: formatCreditCardNumber(values.card_number),
            exp_date: `${values.exp_date.substring(0, 2)}/${values.exp_date.substring(2,4)}`
        })
            .then(response => {

                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setLoader(false);
            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
                setLoader(false);
            })

    }


    return <>
        <div style={{fontSize: "19px", textAlign: "left", borderRadius: "4px", padding: "8px 0px", marginTop: 10}}>
            <div style={{padding: 17, paddingBottom: 0}}>

                <div style={{padding: 17, paddingBottom: 0}}>
                    <Form
                        form={cardForm}
                        name="ManuelTransfer"
                        layout={"vertical"}
                        onFinish={sendRequest}
                        autoComplete="off">
                        <Form.Item
                            label={t("credit_card_number")}
                            name={"card_number"}
                            rules={[{ validator: validateCard }, { required: true, message:"Bu alan zorunlu"}]}
                            normalize={normalize}>
                            <Input maxLenght={16}   inputMode="numeric"  />
                        </Form.Item>

                        <Form.Item
                            label={t("credit_card_date")}
                            name={"exp_date"}
                            rules={[{ validator: validateDate }]}
                            normalize={normalize}>
                            <InputMask  mask="99/99" maskChar={null} >
                                {(inputProps) => {
                                    return <Input maxLenght={16} {...inputProps} inputMode="numeric"  />
                                }}
                            </InputMask>
                        </Form.Item>

                    </Form>
                </div>


            </div>
        </div>
        <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop: 10,}}>
            {t("amount")} <span style={{fontWeight: 900}}>{getAmount}</span>
        </div>

        <div style={{
            fontSize: "19px",
            textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:10, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
            <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoader} onClick={() => { cardForm.submit() }}>{t("pay")}</Button>
        </div>
    </>
};


const CreditCardWithdraw = (props) => {

    const {message}                         = App.useApp();
    const { t, i18n }                               = useTranslation();
    const [getSocketConnected,setSocketConnected]   = useState("socket_connect_wait")
    const [getAmount,setAmount]                     = useState("0.00")
    const [getInRoom,setInRoom]                     = useState(false)
    const [getTransaction,setTransaction]           = useState(props)
    const [getSocketInit,setSocketInit]             = useState(false)

    
    useEffect(() => {
        window.addEventListener("focus", getUpdate);
        return () => {
            window.removeEventListener("focus", getUpdate);
        };
    }, []);
    
    const getUpdate = () => {

        ApiPostPaymentGateway(`/public/payment/transaction/${getTransaction.transaction_id}/view`)
            .then(response => {


                if(response.status !== "success"){
                    throw new Error(response.message)
                }

                setTransaction(response.result);

                setInRoom(response.result.status.value !== "payment_waiting_room");

                if(getSocketInit){
                    getSocketInit.connect();
                }

                if(response.result.status.value === "payment_success"){
                    setTimeout(() => {
                        window.location.href = response.result.success_callback;
                    },2000)
                }

                if(response.result.status.value === "payment_failed"){
                    setTimeout(() => {
                        window.location.href = response.result.error_callback;
                    },2000)
                }

            })
            .catch(error => {
                message.error(error?.response?.data?.message === undefined ? error.message : error?.response?.data?.message);
            })

    }


    useEffect(() => {


        setInRoom(getTransaction.status.value !== "payment_waiting_room");

        const formatter = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: getTransaction.currency.value
        });

        console.log(formatter.format(getTransaction.amount))

        setAmount(formatter.format(getTransaction.amount));


        const SocketConnectServer = io(Settings.socket_server,{
            query: {
                merchant_id: getTransaction.merchant_id,
                transaction_id: getTransaction.transaction_id,
                sender: "client"
            }
        });

        setSocketInit(SocketConnectServer);

        
        SocketConnectServer.on('connect', ()=>{

            SocketConnectServer.emit("public:payment:join:send",{random:Math.random()})

            console.info("Soket bağlantısı sağlandı");

            setSocketConnected("socket_connect_ok");

            setTimeout(()=>setSocketConnected("socket_connected"),500)

        });

        SocketConnectServer.on('public:payment:check:receiver] ', ()=>{


            console.info("public:payment:check:receiver");

        });

        SocketConnectServer.on("disconnect", (disconnect) => {
            getUpdate();
            setInterval(() => {
                SocketConnectServer.connect();
            }, 1000);
            setSocketConnected("disconnect");
            console.error("Soket bağlantısı koptu");
        });

        SocketConnectServer.on("system:payment:list:receiver", (data) => {
            getUpdate();
            console.info("Soket bağlantısı güncellendi","system:payment:list:receiver");
        });

        SocketConnectServer.on("merchant:payment:create:receiver", (data) => {

            console.info("Soket bağlantısı güncellendi","merchant:payment:create:receiver");

        });


        SocketConnectServer.on("connect_error", () => {
            setInterval(() => {
                SocketConnectServer.connect();
            }, 1000);
        });


        return () => {

            SocketConnectServer.offAny()
            SocketConnectServer.removeAllListeners();
            SocketConnectServer.disconnect();
            setSocketConnected("socket_connect_no");

            console.info("Soket bağlantısı güncellendi","Bağlantı kapatıldı");

        }

    }, []);


    if(getSocketConnected === "socket_connected"){

        if(getInRoom){
            return (<>

                <div style={{fontSize: "22px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", fontWeight: 800, textTransform: "uppercase", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", background: "#000000", color: "white"}}>
                    CREDIT CARD WITHDRAW
                </div>

                {
                    ["take_card"]
                        .includes(getTransaction?.status?.value) &&
                            <CreditCardInformationComponent
                                getTransaction={getTransaction} getAmount={getAmount}
                            />
                }


                { [ "info_ok","payment_on_process"].includes(getTransaction?.status?.value) &&
                    <PaymentWait
                       message={t("please_wait")}
                    />
                }

                { ["payment_success"].includes(getTransaction?.status?.value) && <PaymentOk getTransaction={getTransaction} getAmount={getAmount} /> }

                { ["payment_failed"].includes(getTransaction?.status?.value) && <PaymentNO getTransaction={getTransaction} getAmount={getAmount} /> }


                <div style={{width:"100%", display:"flex", justifyContent:"center", position:"absolute", top:-100}}>
                    <img src={`${Settings.cdn}/${getTransaction.merchant.logo}`} alt={getTransaction.merchant.title} height={90} style={{width:"fit-content"}}/>
                </div>
                <div style={{width:"100%", display:"flex", justifyContent:"center", position:"absolute", bottom:-50}}>
                    <img src={PaymentMethods} alt={getTransaction.merchant.title} height={35} style={{width:"fit-content"}}/>
                </div>

            </>)
        }else{
            return (<>
                <div style={{width:"100%", height: "auto", display:"flex", justifyContent:"center", alignItems:"center", background:"#FFFFFF"}}>

                    <div style={{width:450, height: "auto", padding:20, background:"#FFFFFF", minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>

                        <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color:"black" }} spin />} />
                        <div style={{ textAlign:"center" }}>
                            <div style={{fontSize:17, fontWeight:400}}>{t("in_wait")}</div>
                        </div>


                    </div>

                </div>
            </>)
        }

    }else{

        return (<>
            <div style={{width:"100%", height: "auto", display:"flex", justifyContent:"center", alignItems:"center", background:"#FFFFFF"}}>

                <div style={{width:"100%", height: "auto", padding:20, background:"#FFFFFF", minHeight:450, borderRadius:6, display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center"}}>

                    <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color:"black" }} spin />} />
                    <div style={{ textAlign:"center" }}>
                        <div style={{fontSize:17, fontWeight:400}}>Bağlantı Sağlanıyor</div>
                    </div>

                </div>

            </div>
        </>)

    }

};

export default CreditCardWithdraw;